import axios from 'axios';

// axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '/server/' : process.env.BASE_URL;
axios.defaults.baseURL = '/server/';

let mxnAuth = {
    created: ()=> {
        // init
        // console.info("axios URL",axios.defaults)
        // console.info("axios ",process.env)
        // console.info("axios confiog",myApi)
    },
    doAuth: (user, pass)=> {
        return axios.post('/login',{
            email: user,
            password: pass,
        });
    },
    doCheckSession: (token)=> {
        return axios.get('/auth');
    },
    doValidateOTP: (otp)=> {
        return axios.post('/otp', {
            otp: otp
        });
    },
    doGetOTP: (email)=> {
        return axios.post('/getOTP', {
            email: email
        });
    },
    forgotPassword: (email)=> {
        return axios.post('/forgot-password', {
            email: email
        });
    },
    validateForgotPasswordKey: (key)=> {
        return axios.post('/forgot-password-key', {
            key: key
        });
    },
    doLogout: ()=> {
        return axios.post('/logout');
    },
    updateUserPassword: (userId, params) => {
        return axios.put('/user/password/' + userId,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

// error handling
axios.interceptors.response.use(
    response => response,
    error => {
        let res = error.response;
        if (res.status === 401 && false) {
            console.error(res.data)
            // TODO: apply clear session
            localStorage.clear();

            window.location.href = '/login';

        }
        // console.error(res.data)
        return Promise.reject(error);
});

export default mxnAuth;
