import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import _ from 'lodash';
import mxnSwal from '@/mixins/toast'

axios.defaults.baseURL = '/server/';

Vue.use(Vuex)

// TODO: handle state
const defaultState = () => {
	return {
		userInfo: {},
		patientInfo: {},
		isPreLoader: false,
		session: localStorage.getItem('_SESSION'),
		postDMResponse: [],
		getAttachement: [],
		uploadPercentage: 0,
		// roleList: ['ADMIN','FRONTDESK','DIAGNOSTIC','BILLING','RELEASING','DOCTOR','PATIENT'],
		roleList: [],
		rolesAndPermissions: [],
		branchList: [],
		productList: []
	}
}

// TODO: state
const state = defaultState

// TODO: handle mutations
const mutations = {
	preLoader (state, action) {
		state.isPreLoader = action
	},
	storeInfo (state, info) {
		state.userInfo = info
	},
	storePatientInfo (state, info) {
		state.patientInfo = info
	},
	resetState (state) {
		Object.assign(state, defaultState())
	},
	storeSession (state, session) {
		state.session = session
	},

	// upload file mutation
	postDMS(state, response) {
        state.postDMResponse = response
    },
	getDMS(state, response) {
        state.getAttachement = response
    },
	removeAttachment(state, id) {
		const i = state.getAttachement.map(item => item.dms_id).indexOf(id);
		state.getAttachement.splice(i, 1);
	},

	// roles and permissions
	setRoleList(state, response) {
		state.roleList = response
	},
	setPermissions(state, response) {
		state.rolesAndPermissions = response
	},

	// inventory
	setBranchList(state, response) {
		state.branchList = response
	},
	setProductList(state, response) {
		state.productList = response
	},
}

// TODO: handle actions
const actions = {
	doStoreData({commit}, data) {
		commit('storeInfo', data)
	},
	doStorePatient({commit}, data) {
		commit('storePatientInfo', data)
	},
	doPreLoader({commit}, data) {
		commit('preLoader', data)
	},
	resetStateDetails({commit}) {
		commit('resetState')
	},
	// TODO: for user first login/relogin
	setSession({commit}, session) {
		commit('storeSession', session)
	},

	// Upload file action
	uploadResultFile({commit, state, dispatch}, params) {
		let id = params.get('dms_transactionid');
		return axios.post('/dms',
			params,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					"Authorization" : state.session,
				},
				onUploadProgress: function( progressEvent ) {
					state.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
				}.bind(this)
			}).catch(error => {
				console.info('error: ',error)
                mxnSwal.toast('error', 'Error encountered while uploading files, please contact your admin.')
			})
			.then(response => {

				if (response && params.get("dms_type") === 'RESULT') {
					commit('postDMS', response.data)
					dispatch('getAttachement', id)
				}
				setTimeout(() => state.uploadPercentage = 0, 1000)
			});
	},
	getAttachement({commit, state}, id) {
		// TODO: remove current content
		state.getAttachement = []
		return axios.get('/dms-transaction/' + id,
			{
				headers: {
					"Authorization" : state.session,
				}
			})
			.then(response => {
				commit('getDMS', response.data)
			});
	},
	removeUploadedFile({commit, state}, id) {
		return axios.delete('/dms/' + id,
			{
				headers: {
					"Authorization" : state.session,
				}
			}).then(response => {
				commit('removeAttachment', id)
			});
	},
	downloadUploadedFile({commit, state}, param) {
		return axios.get('/dms/' + param.dms_id,
			{
				responseType: 'blob',
				headers: {
					"Authorization" : state.session,
				}
			})
			.then(response => {
				let headerLine = response.headers['content-disposition'];
				let startFileNameIndex = headerLine.indexOf('"') + 1
				let endFileNameIndex = headerLine.lastIndexOf('"');
				let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
				let fileURL = window.URL.createObjectURL(new Blob([response.data]));
				let fileLink = document.createElement('a');
			 
				fileLink.href = fileURL;
				fileLink.setAttribute('download', filename );
				document.body.appendChild(fileLink);
			  
				fileLink.click();
			});
	},

	// get roles
	getRoles({commit, state}) {
		// TODO: remove current content
		state.roleList = []
		return axios.get('/roles',
			{
				headers: {
					"Authorization" : state.session,
				}
			})
			.then(response => {
				commit('setRoleList', response.data);
				state.isPreLoader = false;
			});
	},
	getPermissions({commit, state}) {
		state.rolesAndPermissions = []
		return axios.get('/permissions',
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        })
		.then(response => {
			commit('setPermissions', response.data);
			state.isPreLoader = false;
		});
	},
	removePermission({commit, state}, id) {
		return axios.delete('/role/' + id,
		{
		   headers: {
			   "Authorization" : localStorage.getItem('_SESSION')
		   }
	   }).then(response => {
		// commit('setPermissions', response.data)
			// state.roleList = _.remove(state.roleList, {role_id: id});
			state.roleList = state.roleList.filter(row => row.role_id != id);
			state.isPreLoader = false
		});
	},

	// Branch
	getBranchList({commit, state}) {
		state.branchList = []
		return axios.get('/branches',
			{
				headers: {
					"Authorization" : state.session,
				}
			})
			.then(response => {
				commit('setBranchList', response.data);
				state.isPreLoader = false
			});
	},
	getProductList({commit, state}, v) {
		state.productList = [];
		let p = {
			page: 1,
			sortField: "product_name",
			sortType: "ASC",
			search: ''
		}
		return axios.post('/products',p,
			{
				headers: {
					"Authorization" : state.session,
				}
			})
			.then(response => {
				commit('setProductList', response.data.list || []);
			});
			
	},
	importProduct({commit, state, dispatch}, params) {
		return axios.post('/products-import',
			params,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					"Authorization" : state.session,
				},
				onUploadProgress: function( progressEvent ) {
					state.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
				}.bind(this)
			}).catch(error => {
				console.info('error: ',error)
                mxnSwal.toast('error', 'Error encountered while uploading files, please contact your admin.')
			})
			.then(response => {

				if (response.data) {
					mxnSwal.toast('success', response.data)
				}
				
				setTimeout(() => state.uploadPercentage = 0, 1000)
			});
	},
}

// TODO: handle getters
const getters = {
	isPreLoader : state => {
			return state.isPreLoader
	},
	userInfo : state => {
			return state.userInfo
	},
	patientInfo : state => {
			return state.patientInfo
	},
	getUserFullName : state => {
		return state.userInfo.user_firstname + " " + state.userInfo.user_lastname;
	},
	isSuperAdmin : state => {
		return state.userInfo.user_id === "1"
	},
	isPatient : state => {
		const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		return !regex.test(state.userInfo.user_email);
	},
	roleList : state => {
		return state.roleList;
	},
	rolesAndPermissions : state => {
		return state.rolesAndPermissions;
	},

	// upload
	uploadProgress : state => {
		return state.uploadPercentage
	},
	getAttachement: state => {
		return state.getAttachement
	},
	// branch
	branchList: state => {
		return state.branchList
	},
	productList: state => {
		return state.productList
	},

	hasPermission : (state) => (access) => {
		let userAccess = state.userInfo.role_permissions;
		return userAccess.some(role => access.some(p => role === p))
	}
}

// TODO: handle modules
const modules = {}
export default new Vuex.Store({
	state,
    getters,
    actions,
    mutations,
	modules
})
