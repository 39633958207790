import _ from 'lodash'
import mxnAuth from '@/mixins/auth'
import mxnSwal from '@/mixins/toast'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'Sidebar',
    props: {
        pageMenu: String
    },
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {
        ...mapState([ 'userInfo']),
        ...mapGetters(['hasPermission', 'isSuperAdmin'])
    },
    created() {
        let _this = this;
    },
    watch: {
        $route: function(to, from) {
            // TODO: change active link
            let menu = to.name.toLowerCase().trim()

            this.$emit('getMenu', menu)
        }
    },
    methods: {
        /* viewForm() {
            // this.$store.dispatch('doChangeState')
            this.$emit('doView')
        }, */
        doLogout() {
            let _this = this;
            mxnAuth.doLogout().then( r => {
                // TODO: clear state
                _this.$store.dispatch("resetStateDetails")

                // TODO: apply clear session
                localStorage.clear();

                console.info("redirecting to login")
                mxnSwal.toast("info", "Signed out successfully!")
            }, e => {
                console.log("ERROR Login", e.response);
                mxnSwal.toast("error", e)
                // TODO: clear state
                _this.$store.dispatch("resetStateDetails")

                _this.$router.push("login")

                // TODO: apply clear session
                localStorage.clear();

            });
            // TODO: clear state
            _this.$store.dispatch("resetStateDetails")

            // TODO: apply clear session
            localStorage.clear();
            _this.$router.push("login")
        },
        gotoAccount(){
          this.isOpen = false;
          if(this.$router.currentRoute.name != "Account"){
            this.$router.push("account");
          }
        }
    }
}
