import axios from 'axios';

axios.defaults.baseURL = '/server/';

let mxnCategories = {
    getPermissions: ()=> {
        return axios.get('/permissions',
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    createPermission: (params)=> {
        return axios.post('/role',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    updatePermission: (params, id)=> {
        return axios.put('/role/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnCategories;
