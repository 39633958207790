import axios from 'axios';
import { mapState } from 'vuex'

axios.defaults.baseURL = '/server/';
var session
let mxnOrderItem = {
    getOrderItemList: (params)=> {
        return axios.post('/order-items',
        params,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    getOrderItemById: (id)=> {
        return axios.get('/order-items/' + id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    deleteOrderItem: (id)=> {
        return axios.delete('/order-item/' + id,
         {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    createOrderItem: (params)=> {
        return axios.post('/order-item',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    updateOrderItem: (params, id)=> {
        return axios.put('/order-item/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnOrderItem;
