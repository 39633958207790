
import axios from 'axios';
import { mapState } from 'vuex'

axios.defaults.baseURL = '/server/';
var session
let mxnNotes = {
    getNotesByPatientId: (params,id)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/doctors-notes/' + id,
        params,{
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    createNote: (params)=> {
        return axios.post('/doctors-note',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    updateNote: (params, id)=> {
        return axios.put('/doctors-note/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    deleteNote: (id)=> {
        return axios.delete('/doctors-note/' + id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnNotes;
