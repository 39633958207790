import axios from 'axios';
import { mapState } from 'vuex'

axios.defaults.baseURL = '/server/';
var session
let mxnProduct = {
    getProductList: (params)=> {
        return axios.post('/products',
        params,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    getProductById: (id)=> {
        return axios.get('/product/' + id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    deleteProduct: (id)=> {
        return axios.delete('/product/' + id,
         {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    createProduct: (params)=> {
        return axios.post('/product',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    updateProduct: (params, id)=> {
        return axios.put('/product/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    exportProduct: (params)=> {
        return axios.get('/products-export',
        {
            headers: {
                responseType: 'blob',
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnProduct;
