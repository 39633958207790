<template>
  <div class="bg-gray-100 font-family-karla flex" id="main-body">
    <transition name="fade" mode="out-in">
      <div v-show="isPreLoader" class="bg-white bg-opacity-50 overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 h-screen justify-center md:inset-0 h-modal sm:h-full flex">
        <div style="border-top-color:transparent"
            class="w-16 h-16 border-4 border-blue-700 border-solid rounded-full animate-spin m-auto"></div>
      </div>
    </transition>
      <Sidebar @getMenu="doChangeMenu" :page-menu="pageMenu"/>

      <div class="w-full flex flex-col h-screen overflow-y-hidden">

        <Topbar />

        <Mobilebar @getMenu="doChangeMenu" :page-menu="pageMenu"/>
        
        <div class="w-full overflow-x-hidden border-t flex flex-col text-gray-700">
          <router-view />
        </div>

        <Footer />

      </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import Sidebar from './Sidebar'
import Topbar from './Topbar'
import Mobilebar from './Mobilebar'
import Footer from './Footer'

export default {
  name: 'Dashboard',
  data() {
    return {
      isOpen: false,
      pageMenu: ''
    }
  },
  computed: {
    ...mapState(['isPreLoader']),
    ...mapGetters(['hasPermission']),
  },
  methods: {
    preLoader() {
        this.$store.dispatch('doPreLoader')
    },
    /* doViewForm() {
      this.isOpen = !this.isOpen
    }, */
    doChangeMenu(menu) {
      this.pageMenu = menu;
    }
  },
  created() {
	  	let _this = this;
      // TODO: assign first load menu
      let menu = _this.$route.name.toLowerCase().trim()
		  _this.doChangeMenu(menu);
      // TODO: get roles / usertype
        _this.$store.dispatch('getRoles');
        // TODO: get brabches
        _this.$store.dispatch('getBranchList');
        // TODO: get product
        if (_this.hasPermission(['pharmacy:read'])) {
          _this.$store.dispatch('getProductList');
        }
        
  },
  components: {
    Sidebar,
    Topbar,
    Mobilebar,
    Footer
  }
}
</script>
