import mxnDms from '@/mixins/dms'
import mxnSwal from '@/mixins/toast'
import GenericTable from './../GenericTable'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment';
import _ from 'lodash'

export default {
    name: 'Appointment',
    mixins: [ mxnSwal],
    computed: {
        ...mapState([ 'userInfo', 'patientInfo']),
        ...mapGetters(['isPatient', 'uploadProgress', 'getAttachement', 'hasPermission']),
    },
    data() {
        return {
            showUploadModal: false,
            isProgress: false,
            chartName: '',
            // gta

            gta: {
                search: {
                    show: false
                },
                header: [
                    {name: "Filename"},
                    {name: "Date Uploaded"},
                    {name: "Action"},
                ],
                body: [
                    {name: "dms_name", type: 'text'},
                    {name: "dms_created", type: 'date'},
                    {actions: [
                        {
                            className: 'fa-trash text-red-400',
                            fn: this.removeFile,
                            show: r => {return this.hasPermission(['charts:delete'])}
                        },
                        {
                            className: 'fa-download text-blue-400',
                            fn: this.downloadAttachment,
                            show: r => {return this.hasPermission(['charts:read'])}
                        }
                    ]},
                ],
                query: this.getChartList,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "",
                    sortType: "ASC",
                    search: ''
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        getChartList(str) {
            let _this = this;
            let params = _this.gta.payload;
            let id = _this.patientInfo.patient_id
            params.patient_id = _this.patientInfo.patient_id
            // TODO: preloader
            _this.preLoader(true);

            mxnDms.getChartByPatientId(params).then(r => {
                console.info(r.data)
                let data = {
                    list: r.data,
                    pages: 1,
                    total: r.data.length || 0
                }
                _this.gta.data = data
                // console.info(_this.gta.data)
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                _this.gta.data = {
                    pages: 1,
                    total: 0
                }
                // console.info(e)
                _this.preLoader(false);
            })
        },
        
        handleFileUpload(event){
            let _this = this;
            let file = event.target.files[0];
            // console.info(file)
            let acceptedSize = 3072; // 3mb
            let size = Math.round(file.size / 1024);

            let acceptedFormat = ["jpg", "jpeg", "png", "pdf", "doc", "docx", "xls", "xlsx", "csv", "zip"]
            let splitFilename = file.name.split(".");
            let fileType = splitFilename.pop()
            let filename = splitFilename.join('')
            let found = false;

            if (acceptedFormat.indexOf(fileType) < 0) {
                found = true;
                mxnSwal.toast('error', "File format not accepted");
            } else if (acceptedSize < size) {
                found = true;
                mxnSwal.toast('error', "File size exceed 3MB");
            } else if (_this.chartName === '') {
                found = true;
                mxnSwal.toast('error', "Please set file name first then try to attached a file again.");
                found = true;
                
            }

            if (found) {
                event.target.value = '';
                return;
            }

            
            // console.info(_this.transactionDetails)
            // return;
            _this.isProgress = true;

            let formData = new FormData();

            setTimeout(() => {

                formData.append('upload', file);
                formData.append('dms_transactionid', 0);
                formData.append('dms_patientid', _this.patientInfo.patient_id);
                formData.append('dms_name', _this.chartName);
                formData.append('dms_type', 'CHART');
                formData.append('timestamp', moment().toDate().getTime());
                _this.$store.dispatch("uploadResultFile", formData).then(function() {
                    _this.getChartList();
                });
                setTimeout(() => _this.isProgress = false, 100)

                mxnSwal.toast('success', "File uploaded successfully.");
                // formData.append('file_upload', file);
                // _this.$store.dispatch("importProduct", formData).then(r => {setTimeout(() => _this.showUploadModal = false, 1000)});
                // setTimeout(() => _this.isProgress = false, 100)

                _this.chartName = '';
                _this.showUploadModal = false;
            }, 100)


            // TODO: clear data
            event.target.value = '';
        },
        removeFile(row) {

            let _this = this;
            let id = row.dms_id;
            mxnSwal.confirmation({
                icon: 'info',
                title: "Are you sure you want to remove this file?",
                msg: "This action can't be undone",
                confirmed: () => {
                    // _this.$store.dispatch("removeUploadedFile", id);
                    
                    // TODO: preloader
                    _this.preLoader(true);
                    mxnDms.deleteAttachment(id).then(r => {
                        
                        mxnSwal.toast('success', r.data);
                        _this.getChartList();
                    }, e => {
                        console.info(e)
                    })
                }
            } )
        
        },
        downloadAttachment(row) {
            let _this = this;
            // console.info(dms);return
            _this.$store.dispatch("downloadUploadedFile", row);
        },
    },
    mounted () {
        let _this = this;
        _this.getChartList();
    },
    components: {
        GenericTable
    }
}
