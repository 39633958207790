import mxnNotes from '@/mixins/notes'
import mxnSwal from '@/mixins/toast'
import GenericTable from './../GenericTable'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment';
import _ from 'lodash'

export default {
    name: 'Appointment',
    mixins: [ mxnSwal],
    computed: {
        ...mapState([ 'userInfo', 'patientInfo']),
        ...mapGetters(['hasPermission']),
    },
    data() {
        return {
            showUploadModal: false,
            isProgress: false,
            noteDetails: {},
            notes: '',
            // gta

            gta: {
                search: {
                    show: false
                },
                header: [
                    {name: "Date of Note"},
                    {name: "Action"},
                ],
                body: [
                    {name: "note_created", type: 'date'},
                    {actions: [
                        {
                            className: 'fa-info-circle text-blue-400',
                            fn: row => {this.getNoteDetails(row, 'view')}
                        },
                        {
                            className: 'fa-edit text-blue-400',
                            fn: this.getNoteDetails
                        },
                        {
                            className: 'fa-trash text-red-400',
                            fn: this.removeNote
                        }
                    ]},
                ],
                query: this.getNotetList,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "note_created",
                    sortType: "DESC",
                    search: ''
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        getNotetList(str) {
            let _this = this;
            let params = _this.gta.payload;
            let id = _this.patientInfo.patient_id
            // console.info(id)
            // TODO: preloader
            _this.preLoader(true);

            mxnNotes.getNotesByPatientId(params, id).then(r => {
                _this.gta.data = r.data
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        saveNotesForm() {
            let _this = this;
            // console.info("email:", _this.email)
            // return
            let params = {
                "note_patientid": _this.patientInfo.patient_id,
                "note_content": _this.notes,
                "timestamp": moment().toDate().getTime()
            }

            // _this.userForm();
            // TODO: preloader
            _this.preLoader(true);
            if (_this.noteDetails.note_id) {
                delete params.note_patientid;
                // Create user
                mxnNotes.updateNote(params, _this.noteDetails.note_id).then(r => {
                    // console.info("User List: ", r.data)
                    let d = r.data;
                    mxnSwal.toast('success', d.message)
                    _this.showNoteModal()
                    // TODO: reload
                    _this.getNotetList();

                }, e => {
                    console.info(e)
                    mxnSwal.toast('error', e)
                    _this.preLoader(true);
                });

                return false;
            }
            // Create user
            mxnNotes.createNote(params).then(r => {
                // console.info("User List: ", r.data)
                let d = r.data;
                mxnSwal.toast('success', d.message)
                _this.showNoteModal()
                // TODO: reload
                _this.getNotetList()

            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })
        },
        doSubmitForm() {
            this.$refs.submitNotesForm.click()
        },
        showNoteModal() {
            let _this = this;
            _this.showUploadModal = !_this.showUploadModal;
            _this.clearNote();
        },
        clearNote() {
            let _this = this;
            _this.noteDetails = {};
            _this.notes = '';

        },
        getNoteDetails(row,action) {
            let _this = this;

            _this.showNoteModal()

            _this.noteDetails = row;
            _this.noteDetails.isView = action === 'view';
            _this.notes = row.note_content;
        },
        
        removeNote(row) {
            let _this = this;
            let title = "Are you sure you want to delete this note?";
            let message = "This action cannot be undone."
            mxnSwal.confirmation({
                icon: 'warning',
                title: title,
                msg: message,
                confirmed: () => {
                    _this.preLoader(true);
                    mxnNotes.deleteNote(row.note_id).then(r => {
                        mxnSwal.toast('success', r.data)
                        // this.preLoader(false);
                        this.getNotetList();
                    }, e => {
                        console.info(e.response.data)
                        mxnSwal.toast('error', e.response.data)
                        this.preLoader(false);
                    })
                }
            })
        },
    },
    mounted () {
        let _this = this;

        _this.getNotetList();

    },
    components: {
        GenericTable
    }
}
