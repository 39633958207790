import Vue from 'vue'
import VueRouter from 'vue-router'

// components
import Users from '../views/Users'
import Patients from '../views/Patients'
import Pharmacy from '../views/Pharmacy'
import Orders from '../views/Orders'
import Configuration from '../views/Configuration'
import Reports from '../views/Reports'
import Audit from '../views/Audit'
import PageNotFound from '../views/PageNotFound'
import Cards from '../views/Cards'
import Login from '@/components/Login'
import Dashboard from '@/components/Dashboard'
import Account from '@/components/Account'
import Maintenance from '@/components/Maintenance'

import mxnAuth from '@/mixins/auth'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   component: Maintenance,
  // },
  {
    path: '/',
    alias: '/dashboard',
    // name: 'Dashboard',
    component: Dashboard,
    children: [
      { path: '/', redirect: { name: 'Users' }},
      { path: '/users', name: 'Users', component: Users},
      { path: '/patients', name: 'Patients', component: Patients},
      { path: '/pharmacy', name: 'Pharmacy', component: Pharmacy},
      { path: '/orders', name: 'Orders', component: Orders},
      { path: '/cards', name: 'Cards', component: Cards},
      { path: '/configuration', name: 'Configuration', component: Configuration},
      { path: '/reports', name: 'Reports', component: Reports},
      { path: '/announcements', name: 'Announcements', component: Reports},
      { path: '/audit', name: 'Audit', component: Audit},
      { path: '/account', name: 'Account', component: Account},
    ],
    beforeEnter: (to, from, next) => {
        // console.info("load dashboard", this)
        // console.info(to, from)
        // // Check Session First
        // console.info(mxnAuth);
        // FIRST trap
        let fromHome = to.redirectedFrom === '/' || to.name === 'Login'
        let session = localStorage.getItem("_SESSION")
        if (!session) {
          next('/login');
        } else if (checkPermission(['users:read']) && fromHome){
          next('/users')
        } else if (checkPermission(['patients:read', 'doctors:patients']) && fromHome){
          next('/patients');
        } else if (checkPermission(['pharmacy:read']) && fromHome){
          next('/pharmacy');
        } else if (checkPermission(['orders:read']) && fromHome){
          next('/orders');
        } else if (checkPermission(['cards:read']) && fromHome){
          next('/cards');
        } else if (checkPermission(['reports:read']) && fromHome){
          next('/reports');
        } else if (from.name !== 'Login' && to.redirectedFrom != '/dashboard') {
          checkSession(to, from, next)
        } else  {
          next()
        }

    }
  },
  {
    path: '/login',
    // alias: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      let _session = localStorage.getItem("_SESSION");
      if (_session != null && checkPermission(['users:read'])) {
        next('/users');
      } else if (_session != null && checkPermission(['patients:read', 'doctors:patients'])) {
        next('/patients');
      } else if (_session != null && checkPermission(['pharmacy:read'])) {
        next('/pharmacy');
      } else if (_session != null && checkPermission(['orders:read'])) {
        next('/orders');
      } else if (_session != null && checkPermission(['cards:read'])) {
        next('/cards');
      } else if (_session != null && checkPermission(['reports:read'])) {
        next('/reports');
      } else {
        next();
      }

    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: Login,
    beforeEnter: (to, from, next) => {
      let query = to.query;
      // console.info(to, from);
      if (query.key) {

        validateHasKey(query.key, next)
      } else {
        next('/404')
      }

    }
  },
  {
    // TODO: when none of the above matches
    path: '*',
    // alias: '/404',
    name: 'PageNotFound',
    component: PageNotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const checkPermission = (access) => {
  let userAccess = JSON.parse(atob(localStorage.getItem("_ACCESS")));
  return userAccess.some(role => access.some(p => role === p))
}

const checkSession = (to, from, next, path) => {
  let _session = localStorage.getItem("_SESSION");
  // console.info(path)
  mxnAuth.doCheckSession(_session).then( r => {
    // console.log("Check session", r);
    let data = r.data;
    let meta = to.meta;

    // console.info(to, from)
    // console.info(meta.access, data.user_type)
    // console.info(path)

    // TODO: store user data to vuex
    router.app.$store.dispatch("doStoreData", data)
    let hasPermission = router.app.$store.getters['hasPermission'];
    let isSuperAdmin = data.user_id === "1";
    // console.info(hasPermission(['patients:read']));
    // console.info(path);

    // TODO: redirection security
    let fromHome = to.redirectedFrom === '/' || to.name === 'Login';
    // console.info(hasPermission(['pharmacy:read']), fromHome)
    if (isSuperAdmin && !fromHome ||
        (hasPermission(['patients:read', 'doctors:patients']) && to.name === "Patients") ||
        (hasPermission(['pharmacy:read']) && to.name === "Pharmacy") ||
        (hasPermission(['orders:read']) && to.name === "Orders") ||
        (hasPermission(['cards:read']) && to.name === "Cards") ||
        (hasPermission(['reports:read']) && (to.name === "Reports")) ||
        (to.name === "Announcements" && !isPatient(data.user_email)) || to.name === "Account"){
      next();
    } else {
      next('/404')
    }


  }, e => {
      //swal("Could not log in!", "Please ensure your ID and Password", "error");
      console.log("ERROR Login", e.response);
      // TODO: apply clear session
      localStorage.clear();
      // TODO: redirect to login
      // window.location.href = '/login';
      next('/login');

  });
}

const validateHasKey = (key, next) => {
  mxnAuth.validateForgotPasswordKey(key).then(r => {
    next();
  }, e => {
    next('/404');
  })
}

const isPatient = (user_email) => {
		const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		return !regex.test(user_email);
}


export default router
