import axios from 'axios';

axios.defaults.baseURL = '/server/';
var session;
let mxnAudit = {
    dmsSave: (params)=> {
        let formData = new FormData();
        formData.append('upload', params.file);
        formData.append('dms_transactionid', params.file);
        formData.append('dms_patientid', params.file);
        formData.append('dms_name', params.file);
        formData.append('dms_type', params.file);
        formData.append('notes', params.file);
        return axios.post('/audit-logs',
        params,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    getChartByPatientId: (params) => {
        return axios.get('/patient-chart/' + params.patient_id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    deleteAttachment: (id) => {
        return axios.delete('/dms/' + id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    }
}

export default mxnAudit;
