<template>
<div>
<!--<HeaderNotice />-->
           <!-- Desktop Header -->
        <header class="w-full items-center bg-white py-2 px-6 hidden sm:flex shadow-sm">
            <div class="w-1/2"></div>
            <div class="w-1/2 items-center flex justify-end">
              <div class="relative flex justify-end px-4 text-xs">
                  You're logged in as: {{getUserFullName}}
              </div>
              <div class="relative flex justify-end mr-2" v-if="!isPatient">
                  <router-link to="/announcements" class="realtive flex justify-center items-center z-10 w-12 h-12 rounded-full overflow-hidden hover:bg-gray-300">
                      <i class="fas fa-bullhorn text-xl text-yellow-600"></i>
                  </router-link>
              </div>
              <div class="relative flex justify-end">
                  <button @click="dropDown = !dropDown" class="realtive z-10 w-12 h-12 rounded-full overflow-hidden border-4 border-gray-400 hover:border-gray-300 focus:border-gray-300 focus:outline-none">
                      <img src="../assets/global-clinic-logo.png">
                  </button>
                  <button v-show="dropDown" @click="dropDown = false" class="h-full w-full fixed inset-0 cursor-default"></button>
                  <div v-show="dropDown" class="absolute w-32 bg-white rounded-lg shadow-lg py-2 mt-16 z-50">
                      <a href="#" @click.prevent="gotoAccount" class="block px-4 py-2 account-link ">Account</a>
                  </div>
              </div>
            </div>
        </header>

</div>
</template>

<script>
import { mapGetters } from 'vuex'

import HeaderNotice from './HeaderNotice'

export default {
    name: 'Topbar',
    data() {
        return {
            dropDown: false
        }
    },
    components: {HeaderNotice},
    methods: {
        doClose() {
            this.isUnderConstruction = false;
        },
        gotoAccount(){
          this.dropDown = false;
          if(this.$router.currentRoute.name != "Account"){
            this.$router.push("account");
          }
        }
    },
    computed: {
      ...mapGetters(['getUserFullName', 'isPatient', 'hasPermission']),
    }
}
</script>
