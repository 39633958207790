import mxnCards from '@/mixins/cards'
import mxnCardTypes from '@/mixins/cardTypes'
import mxnSwal from '@/mixins/toast'
import mxnCommon from '@/mixins/common'
import GenericTable from './../GenericTable'
import mxnDate from '@/mixins/date'
import mxnPatients from '@/mixins/patients'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment';
import { _ } from 'core-js'

export default {
    name: 'Card',
    mixins: [mxnCards, mxnSwal, mxnDate, mxnCommon],
    computed: {
        ...mapState([ 'userInfo']),
        ...mapGetters(['hasPermission', 'branchList', 'isSuperAdmin']),
        branchSelection() {
            let _this = this;
            let branchSelectionList = [];
            // if (newList.length !== oldList.length) {
                // console.info("new", newList)
            // let branches = newList;
    
            branchSelectionList = [{
                branch_id: 0,
                branch_name: "All Branches"
            }];
    
            branchSelectionList = [
                ...branchSelectionList,
                ..._this.branchList,
                {
                branch_id: -1,
                branch_name: "UNASSIGNED"
                }
            ];
    
            // TODO: initialize first selection
            
            return branchSelectionList || [];
        },
    },
    data() {
        return {
            showModal: false,
            assignModal: false,
            utilizationModal: false,
            cardId: '',
            cardNumber: '',
            cardType: '',
            cardExpDate: '',
            cardDiscount: 0,
            cardLabDiscount: 0,
            cardDiagDiscount: 0,
            cardConsultDiscount: 0,
            cardLimit: 0,
            cardTypeList: [],
            branch: '',
            assignedBranch: {},

            // gta
            searchInput: '',
            gta: {
                title: 'Cards',
                search: {
                    placeholder: "Card Number"
                },
                header: [
                    {name: "Card Number"},
                    {name: "Card Type"},
                    {name: "Expiration Date"},
                    {name: "Action"},
                ],
                body: [
                    {name: "card_number", type: 'text'},
                    {name: "type_name", type: 'text'},
                    {name: "card_expiration", type: 'date', format: 'MMMM DD, YYYY'},
                    {actions: [
                        {
                            className: 'fa-user-edit text-blue-400',
                            fn: this.doGetDetails,
                            show: this.showUpdate
                        },
                        {
                            className: 'fa-user-tag text-blue-400',
                            fn: this.assignPatient
                        },
                        {
                            className: 'fa-history text-blue-400',
                            fn: this.showUtilization
                        },
                        {
                            className: 'fa-trash text-red-400',
                            fn: this.removeCards,
                            show: this.showDelete
                        },
                        
                    ]},
                ],
                query: this.getCardList,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "card_number",
                    sortType: "ASC",
                    search: ''
                }
            },
            
            patientGta: {
                title: 'Assigned Patient',
                search: {
                    placeholder: 'Reference No., First or Last Name'
                },
                header: [
                    {name: "Reference No."},
                    {name: "Full Name"},
                    {name: "Branch"},
                    {name: "Action"},
                ],
                body: [
                    {name: "patient_referenceid", type: 'text'},
                    {type: 'method', fn: row => row.patient_lastname + ", " +row.patient_firstname },
                    {type: 'method', fn: row => _.find(this.branchList, {branch_id: row.patient_branchid}).branch_name },
                    {actions: [
                        {
                            className: 'fa-toggle-on text-green-400',
                            fn: this.updateAssignStatus,
                            show: this.showActiveChangeStatus
                        },
                        {
                            className: 'fa-toggle-off text-red-400',
                            fn: this.updateAssignStatus,
                            show: this.showDeactivatedChangeStatus
                        },
                        {
                            className: 'fa-toggle-off text-gray-400 pointer-events-none',
                            fn: () => {return},
                            show: this.showDisabledChangeStatus
                        },
                    ]},
                ],
                query: this.getPatientList,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "patient_created",
                    sortType: "DESC",
                    search: ''
                }
            },

            utilizationGta: {
                title: 'Card Utilization Details',
                search: {
                    show: false
                },
                header: [
                    {name: "Date Used"},
                    {name: "Branch"},
                    {name: "Patient full name"},
                    {name: "Patient Ref. No."},
                    {name: "Appointment status"},
                ],
                body: [
                    {name: "appointment_created", type: 'date'},
                    {type: 'method', fn: row => _.find(this.branchList, {branch_id: row.appointment_branchid}).branch_name },
                    
                    {type: "method", fn: row => row.patient_lastname + ', ' + row.patient_firstname},
                    {name: "patient_referenceid", type: 'text'},
                    {name: "appointment_status", type: 'text'},
                ],
                query: this.utilizationList,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "appointment_created",
                    sortType: "DESC",
                    search: ''
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        showUpdate(row) {
            return this.hasPermission(['cards:update'])
        },
        showDelete(row) {
            return this.hasPermission(['cards:delete'])
        },
        getCardList(str) {
            let _this = this;
            let params = _this.gta.payload;
            params.search = str || '';

            // TODO: preloader
            _this.preLoader(true);

            mxnCards.doGetCards(params).then(r => {
                _this.gta.data = r.data

                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        getCardTypeList(isLoaded) {
            let _this = this;

            let params = {
                page: 1,
                sortField: "type_name",
                sortType: "ASC",
                search: ''
            };

            mxnCardTypes.doGetCardType(params).then(r => {
                _this.cardTypeList = r.data.list || [];

                // upon init
                if (isLoaded) {
                    _this.getCardList()
                }
            }, e => {
                console.info(e)
            })
        },
        getPatientList(str) {
            let _this = this;
            let params = _this.patientGta.payload;
            params.search = str || '';
            params.branch_id = _this.branch;

            mxnPatients.doGetPatients(params).then(r => {
                _this.assignedBranch[_this.cardId] = [];
                // get assigned branches
                if (r.data.list) {
                    r.data.list.map(i => {
                        if (i.membership_card && i.membership_card.xref_cardid === _this.cardId) {
                            _this.assignedBranch[_this.cardId].push(i.patient_branchid)
                        }
                        
                    })
                }
                _this.patientGta.data = r.data
            }, e => {
                console.info(e)
            })
        },

        selectedCardType() {
            let _this = this
            let types = _.find(_this.cardTypeList, {type_id: _this.cardType});
            // console.info(types)
            _this.cardDiscount = types.type_discountpercent
            _this.cardLabDiscount = types.type_laboratorydiscount
            _this.cardDiagDiscount = types.type_diagnosticdiscount
            _this.cardConsultDiscount = types.type_consultationdiscount
            _this.cardLimit = types.type_consultationlimit
        },
        saveCardForm() {
            let _this = this;
            // return
            let params = {
                "card_number": _this.cardNumber,
                "card_typeid": _this.cardType,
                "card_consultationlimit": _this.cardLimit,
                "card_discountpercent": _this.cardDiscount,
                "card_laboratorydiscount": _this.cardLabDiscount,
                "card_diagnosticdiscount": _this.cardDiagDiscount,
                "card_consultationdiscount": _this.cardConsultDiscount,
                "card_expiration": moment(_this.cardExpDate.split("/").reverse().join("-")).toDate().getTime(),
            }
            // TODO: preloader
            _this.searchInput = _this.gta.payload.search
            _this.preLoader(true);
            if (_this.cardId) {
                delete params.user_password
                // Create user
                mxnCards.doUpdateCard(params, _this.cardId).then(r => {
                    let d = r.data;
                    mxnSwal.toast('success', d)

                    _this.viewForm();

                    // TODO: reload
                    _this.getCardList(_this.searchInput)
                }, e => {
                    console.info(e.response.data)
                    mxnSwal.toast('error', e.response.data)
                    _this.preLoader(false);
                });

                return false;
            }
            // Create user
            mxnCards.doCreateCard(params).then(r => {
                let d = r.data;
                mxnSwal.toast('success', d)
                _this.viewForm();
                // TODO: reload
                _this.getCardList(_this.searchInput)
            }, e => {
                console.info(e.response.data)
                mxnSwal.toast('error', e.response.data)
                _this.preLoader(false);
            })

        },
        doSubmitForm() {
            this.$refs.submitCardForm.click()
        },
        doClearForm() {
            let _this = this;
            _this.cardId = '';
            _this.cardNumber = '';
            _this.cardType = '';
            _this.cardExpDate = '';
            _this.cardDiscount = 0;
            _this.cardLabDiscount = 0;
            _this.cardDiagDiscount = 0;
            _this.cardConsultDiscount = 0;
            _this.cardLimit = 0;
            mxnDate.inputDate("input-date", true)
        },
        doGetDetails(row, isAssignForm) {
            // console.info(row)
            let _this = this;
            _this.preLoader(true);
            mxnCards.doGetCardById(row.card_id).then(r => {
                let d = r.data;

                if (!isAssignForm) {
                    _this.viewForm();
                } else {
                    _this.assignModal = true;
                    _this.getPatientList();
                }
                
                _this.cardId = d.card_id;
                _this.cardNumber = d.card_number;
                _this.cardType = d.card_typeid;
                _this.cardExpDate =  moment(parseFloat(d.card_expiration)).format('DD/MM/YYYY');
                _this.cardDiscount = !isAssignForm ? d.card_discountpercent : d.card_discountpercent + '%'
                _this.cardLabDiscount = !isAssignForm ? d.card_laboratorydiscount : d.card_laboratorydiscount + '%'
                _this.cardDiagDiscount = !isAssignForm ? d.card_diagnosticdiscount : d.card_diagnosticdiscount + '%'
                _this.cardConsultDiscount = !isAssignForm ? d.card_consultationdiscount : d.card_consultationdiscount + '%'
                _this.cardLimit = d.card_consultationlimit
                // TODO: end preloader
                setTimeout(() => {
                    // mxnDate.inputDate("input-date")
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })

        },
        viewForm() {
            let _this = this;
            _this.doClearForm();
            // _this.$emit('doView')
            _this.showModal = !_this.showModal
        },

        removeCards(data,i) {
            let index = i;
            let _this = this;
            mxnSwal.confirmation({
                icon: 'warning',
                title: "Are you sure you want to remove '" + data.card_number + "'?",
                msg: "This action can't be undone",
                confirmed: () => {
                    _this.preLoader(true);
                    mxnCards.doDeleteCard(data.card_id).then(r => {
                        let d = r.data;
                        mxnSwal.toast('success', d)
                        _this.getCardList();
                       
                    }, e => {
                        console.info(e)
                        mxnSwal.toast('error', e)
                        _this.preLoader(true);
                    })
                }
            } )
            
        },
        assignPatient(row) {
            let _this = this;

            _this.doGetDetails(row, true)
        },
        updateAssignStatus(row) {
            let _this = this;
            // console.info(row)
            let details = row;
            let params = {
                patient_id: row.patient_id,
                card_id: _this.cardId,
                branch_id: details.patient_branchid,
            }

            if (row.membership_card) {
                let memberId = row.membership_card.xref_id;
                let fullname = row.patient_lastname + ',' + row.patient_firstname;
                let title = "Are you sure you want to unassign patient " + fullname +"?";
                let message = "This action cannot be undone."
                mxnSwal.confirmation({
                    icon: 'warning',
                    title: title,
                    msg: message,
                    confirmed: () => {
                        _this.preLoader(true);
                        mxnCards.unassignPatient(memberId).then(r => {
                            mxnSwal.toast('success', r.data)
                            this.getPatientList();
                            
                            // TODO: end preloader
                            setTimeout(() => { _this.preLoader(false); }, 100)
                        }, e => {
                            console.info(e.response.data)
                            mxnSwal.toast('error', e.response.data)
                            this.preLoader(false);
                        })
                    }
                });

                return;
            }

            mxnCards.assignPatient(params).then(r => {
                console.info(r)
                // _this.doctorsGta.data = r.data

                // if (_this.gta.data.total === 0 && _this.searchInput !== '') {
                mxnSwal.toast('success', r.data);

                _this.getPatientList();
                // }
            }, e => {
                mxnSwal.toast('error', e.response.data)
                _this.preLoader(false);
            })

        },

        showActiveChangeStatus(row) {
            return row.membership_card && row.membership_card.xref_cardid === this.cardId
        },
        showDeactivatedChangeStatus(row) {
            return !row.membership_card && (this.assignedBranch[this.cardId] && this.assignedBranch[this.cardId].indexOf(row.patient_branchid) < 0)
        },
        showDisabledChangeStatus(row) {
            return (!row.membership_card && (this.assignedBranch[this.cardId] && this.assignedBranch[this.cardId].indexOf(row.patient_branchid) >= 0)) || (row.membership_card && row.membership_card.xref_cardid != this.cardId)
        },
        showUtilization(row) {
            let _this = this;
            _this.utilizationModal = true;
            _this.cardId = row.card_id;
            _this.utilizationList();
        },
        utilizationList(str) {
            let _this = this;
            let params = _this.utilizationGta.payload;
            params.search = str || '';

            _this.preLoader(true);

            mxnCards.getUtilizationDetails(params, _this.cardId).then(r => {
                
                _this.utilizationGta.data = r.data


                // TODO: end preloader
                setTimeout(() => { _this.preLoader(false); }, 100)
            }, e => {
                console.info(e)
            })
        }

    },
    mounted () {
        let _this = this;
        
        _this.branch = _this.userInfo.role_dataaccess === 'BRANCH' ? _this.userInfo.user_branchid : 0;

        // TODO: load userlist
        _this.getCardTypeList(true);

        // _this.getPatientList();
    },
    components: {
        GenericTable
    }
}
