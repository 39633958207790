import mxnCardTypes from '@/mixins/cardTypes'
import mxnSwal from '@/mixins/toast'
import mxnCommon from '@/mixins/common'
import GenericTable from './../GenericTable'

export default {
    name: 'Cards',
    mixins: [mxnCardTypes, mxnSwal, mxnCommon],

    data() {
        return {
            cardTypeId: '',
            cardName: '',
            cardConsultationlimit: 0,
            cardDiscountpercent: 0,
            cardLaboratoryDiscountpercent: 0,
            cardDiagnosticDiscountpercent: 0,
            cardConsultationDiscountpercent: 0,
            isOpen: false,
            // gta
            cardTypeList: [],
            // gta
            searchInput: '',
            gta: {
                search: {
                    placeholder: "Type Name",
                    show: false
                },
                header: [
                    {name: "Name"},
                    {name: "Consultation Limit"},
                    {name: "Discount in percent"},
                    {name: "Laboratory Discount"},
                    {name: "Diagnostic Discount"},
                    {name: "Consultation Discount"},
                    {name: "Action"}
                ],
                body: [
                    {name: "type_name", type: 'text'},
                    {name: "type_consultationlimit", type: 'text'},
                    {name: "type_discountpercent", type: 'text'},
                    {name: "type_laboratorydiscount", type: 'text'},
                    {name: "type_diagnosticdiscount", type: 'text'},
                    {name: "type_consultationdiscount", type: 'text'},
                    {actions: [
                        {
                            className: 'fa-edit text-blue-400',
                            fn: this.getCardTypeDetails
                        },
                        {
                            className: 'fa-trash text-blue-400',
                            fn: this.removeCardType
                        },
                    ]},
                ],
                query: this.getCardTypeList,
                data: {total:0},
                size: 10,
                payload: {
                    page: 1,
                    search: '',
                    sortField: "type_name",
                    sortType: "ASC"
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        getCardTypeList(str) {
            let _this = this;

            let params = _this.gta.payload;
            params.search = str || ''
            // TODO: preloader
            _this.preLoader(true);

            mxnCardTypes.doGetCardType(params).then(r => {
                _this.gta.data = r.data
                // Update global list
                // _this.$emit('update-categories', r.data)
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        saveCardTypeForm(s) {
            let _this = this;
            // return
            let params = {
                "type_name": _this.cardName,
                "type_consultationlimit": _this.cardConsultationlimit,
                "type_discountpercent": _this.cardDiscountpercent,
                "type_laboratorydiscount": _this.cardLaboratoryDiscountpercent,
                "type_diagnosticdiscount": _this.cardDiagnosticDiscountpercent,
                "type_consultationdiscount": _this.cardConsultationDiscountpercent,
            }

            // TODO: preloader
            _this.preLoader(true);
            if (_this.cardTypeId) {
                // Create user
                mxnCardTypes.doUpdate(params, _this.cardTypeId).then(r => {
                    let d = r.data;
                    mxnSwal.toast('success', d)
                    _this.viewForm()
                    // TODO: reload
                    _this.getCardTypeList()
                }, e => {
                    console.info(e)
                    mxnSwal.toast('error', e)
                    _this.preLoader(true);
                });

                return false;
            }
            // Create user
            mxnCardTypes.doCreate(params).then(r => {
                let d = r.data;
                mxnSwal.toast('success', d)
                _this.viewForm()
                // TODO: reload
                _this.getCardTypeList()
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })

        },
        getCardTypeDetails(row) {
            let _this = this;
            // _this.viewForm()
            // Create user
            _this.preLoader(true);
            mxnCardTypes.doGetCardTypeById(row.type_id).then(r => {
                let d = r.data;
                _this.viewForm();
                _this.updateCardType(d)
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })
        },
        doSubmitForm() {
            this.$refs.submitCardTypeForm.click()
        },
        doClearForm() {
            let _this = this;

            _this.cardTypeId = ''
            _this.cardName = ''
            _this.cardDiscountpercent = 0
            _this.cardLaboratoryDiscountpercent = 0
            _this.cardDiagnosticDiscountpercent = 0
            _this.cardConsultationDiscountpercent = 0
            _this.cardConsultationlimit = 0
        },
        removeCardType(data,i) {
            let index = i;
            let _this = this;
            mxnSwal.confirmation({
                icon: 'warning',
                title: "Are you sure you want to remove '" + data.type_name + "'?",
                msg: "This action can't be undone",
                confirmed: () => {
                    _this.preLoader(true);
                    mxnCardTypes.doDelete(data.type_id).then(r => {
                        let d = r.data;
                        mxnSwal.toast('success', d)
                        _this.getCardTypeList();
                       
                    }, e => {
                        console.info(e)
                        mxnSwal.toast('error', e)
                        _this.preLoader(true);
                    })
                }
            } )
            
        },
        updateCardType(d) {
            let _this = this;
            // _this.viewForm();
            _this.cardTypeId = d.type_id
            _this.cardName = d.type_name
            _this.cardDiscountpercent = d.type_discountpercent
            _this.cardLaboratoryDiscountpercent = d.type_laboratorydiscount
            _this.cardDiagnosticDiscountpercent = d.type_diagnosticdiscount
            _this.cardConsultationDiscountpercent = d.type_consultationdiscount
            _this.cardConsultationlimit = d.type_consultationlimit
        },
        viewForm() {
            let _this = this;
            _this.doClearForm();
            _this.isOpen = !_this.isOpen
        }
    },
    mounted () {
        let _this = this;
        // TODO: load card list
        _this.getCardTypeList();

    },
    components: {
        GenericTable
    }
}
