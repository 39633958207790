import mxnDms from '@/mixins/dms'
import mxnReports from '@/mixins/reports'
import mxnSwal from '@/mixins/toast'
import GenericTable from './../GenericTable'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment';
import _ from 'lodash'

export default {
    name: 'Appointment',
    mixins: [ mxnSwal],
    computed: {
        ...mapState([ 'userInfo', 'patientInfo']),
        ...mapGetters(['isSuperAdmin', 'uploadProgress', 'getAttachement', 'hasPermission', 'roleList']),
        
    },
    watch:{
        $route (to, from){
            let _this = this;
            // console.info(to, from)
            _this.isAnnouncements = to.name.toLowerCase().indexOf("announcements") >= 0;

            _this.getReportList();
        }
    },
    data() {
        return {
            showUploadModal: false,
            isProgress: false,
            reportFileName: '',
            selectedRole: 2,
            isAnnouncements: false,
            // gta

            gta: {
                search: {
                    show: false
                },
                header: [
                    {name: "Filename"},
                    {name: "Uploaded By"},
                    {name: "Date Uploaded"},
                    {name: "Action"},
                ],
                body: [
                    {name: "dms_name", type: 'text'},
                    {name: "", type: 'method', fn: row => {return row.dms_userid === '1' ? 'Admin' : row.user_lastname + ", " + row.user_firstname}},
                    {name: "dms_created", type: 'date'},
                    {actions: [
                        {
                            className: 'fa-trash text-red-400',
                            fn: this.removeFile,
                            show: r => {return this.hasPermission(['reports:delete']) && (!this.isAnnouncements || this.isSuperAdmin)}
                        },
                        {
                            className: 'fa-download text-blue-400',
                            fn: this.downloadAttachment,
                            show: r => {return (this.hasPermission(['reports:read']) || this.isAnnouncements)}
                        }
                    ]},
                ],
                query: this.getChartList,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "dms_id",
                    sortType: "DESC",
                    search: ''
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        getReportList(str) {
            let _this = this;
            let params = _this.gta.payload;
            params.search = str || '';
            params.role_id = _this.isSuperAdmin ? _this.selectedRole : _this.userInfo.role_id;
            // TODO: preloader
            _this.preLoader(true);

            let list = _this.isAnnouncements ? 'getAnnouncementList' : 'getReportList';
            
            mxnReports[list](params).then(r => {
                _this.gta.data = r.data
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        
        handleFileUpload(event){
            let _this = this;
            let file = event.target.files[0];
            // console.info(file)
            let acceptedSize = 3072; // 3mb
            let size = Math.round(file.size / 1024);

            let acceptedFormat = ["jpg", "jpeg", "png", "pdf", "doc", "docx", "xls", "xlsx", "csv", "zip"]
            let splitFilename = file.name.split(".");
            let fileType = splitFilename.pop()
            let filename = splitFilename.join('')
            let found = false;

            if (acceptedFormat.indexOf(fileType) < 0) {
                found = true;
                mxnSwal.toast('error', "File format not accepted");
            } else if (acceptedSize < size) {
                found = true;
                mxnSwal.toast('error', "File size exceed 3MB");
            } else if (_this.reportFileName === '') {
                found = true;
                mxnSwal.toast('error', "Please set file name first then try to attached a file again.");
                found = true;
                
            }

            if (found) {
                event.target.value = '';
                return;
            }

            
            // console.info(_this.transactionDetails)
            // return;
            _this.isProgress = true;

            let formData = new FormData();

            setTimeout(() => {

                formData.append('upload', file);
                formData.append('dms_transactionid', 0);
                formData.append('dms_patientid', 0);
                formData.append('dms_name', _this.reportFileName);
                formData.append('dms_type', 'REPORT');
                formData.append('timestamp', moment().toDate().getTime());
                _this.$store.dispatch("uploadResultFile", formData).then(r => {
                    // console.info(r)
                    _this.getReportList();
                });
                setTimeout(() => _this.isProgress = false, 100)

                mxnSwal.toast('success', "File uploaded successfully.");
                // formData.append('file_upload', file);
                // _this.$store.dispatch("importProduct", formData).then(r => {setTimeout(() => _this.showUploadModal = false, 1000)});
                // setTimeout(() => _this.isProgress = false, 100)

                _this.reportFileName = '';
                _this.showUploadModal = false;
            }, 100)


            // TODO: clear data
            event.target.value = '';
        },
        removeFile(row) {

            let _this = this;
            let id = row.dms_id;
            mxnSwal.confirmation({
                icon: 'info',
                title: "Are you sure you want to remove this file?",
                msg: "This action can't be undone",
                confirmed: () => {
                    // TODO: preloader
                    _this.preLoader(true);
                    _this.$store.dispatch("removeUploadedFile", id).then(r => {

                        mxnSwal.toast('success', "File successfully deleted.");
                        _this.getReportList();
                    });
                }
            } )
        
        },
        downloadAttachment(row) {
            let _this = this;
            // console.info(dms);return
            _this.$store.dispatch("downloadUploadedFile", row);
        },
    },
    created () {
        let cRoute = this.$router.currentRoute;
        this.isAnnouncements = cRoute.path.indexOf("announcements") >= 0;
    },
    mounted () {
        let _this = this;
        _this.getReportList();
    },
    components: {
        GenericTable
    }
}
