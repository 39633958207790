import mxnUsers from '@/mixins/users'
import mxnDepartments from '@/mixins/departments'
import mxnSwal from '@/mixins/toast'
import mxnCommon from '@/mixins/common'
import mxnBranches from '@/mixins/branches'
import GenericTable from './../GenericTable'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment';

export default {
    name: 'User',
    mixins: [mxnUsers, mxnDepartments, mxnSwal, mxnCommon, mxnBranches],
    computed: {
        ...mapState([ 'userInfo']),
        ...mapGetters(['roleList', 'hasPermission', 'branchList', 'isSuperAdmin']),

        selectedRoleInfo(){

          let role = this.roleList.filter((r)=>{
            return r.role_id === this.userType;
          });
          console.log("ROLE---", role[0])
          return role[0] || {
            role_dataaccess: 'GLOBAL',
            role_departmentaccess: 'GLOBAL'
          };
        }
    },
    data() {
        return {
            dropDown: false,
            showModal: false,
            isPwd: "password",
            isPwdConfirm: "password",
            // roleList: ['ADMIN','FRONTDESK','DIAGNOSTIC','BILLING','RELEASING','DOCTOR','PATIENT'],
            department: [],
            // branchList: [],
            userId: '',
            firstName: '',
            lastName: '',
            middleName: '',
            password: '',
            confirmPassword: '',
            isPasswordMatched: false,
            email: '',
            dept: '',
            userType: '',
            userStatus: '',
            branch: '0',
            dataAccess: '',

            isOpenUserPasswordForm: false,
            userIdPasswordForm: null,
            passwordFormValue: "",

            // gta
            searchInput: '',
            gta: {
                title: 'Medical Staffs',
                search: {
                    placeholder: "First name or Last name"
                },
                header: [
                    {name: "First Name"},
                    {name: "Last Name"},
                    {name: "Email"},
                    {name: "Type"},
                    {name: "Status"},
                    {name: "Action"},
                ],
                body: [
                    {name: "user_firstname", type: 'text'},
                    {name: "user_lastname", type: 'text'},
                    {name: "user_email", type: 'text'},
                    {name: "user_roleid", type: 'method', fn: row => {let role = _.find(this.roleList, {role_id: row.user_roleid}) || {}; if(row.user_id == 1){return "SUPER ADMIN";} return role.role_name || 'UNASSIGNED'}},
                    {name: "user_status", type: 'text'},
                    {actions: [
                        {
                            className: 'fa-user-edit text-blue-400',
                            fn: this.doGetDetails,
                            show: this.restrictToSuperAdminChangePassword
                        },
                        {
                            className: 'fa-key text-blue-400',
                            fn: this.showUserPasswordForm,
                            show: this.restrictToSuperAdminChangePassword
                        },
                        {
                            className: 'fa-toggle-on text-green-400',
                            fn: this.updateStatus,
                            show: this.showActiveChangeStatus
                        },
                        {
                            className: 'fa-toggle-off text-red-400',
                            fn: this.updateStatus,
                            show: this.showDeactivatedChangeStatus
                        },
                        {
                            className: 'fa-lock text-red-400',
                            fn: this.updateLockStatus,
                            show: this.showLockFeature
                        },
                    ]},
                ],
                query: this.getUserList,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "user_lastname",
                    sortType: "ASC",
                    search: ''
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        showActiveChangeStatus(row) {
            return (row.user_id != "1" && row.user_status === 'ACTIVE') && this.hasPermission(['users:activate'])
        },
        showDeactivatedChangeStatus(row) {
            return ((this.isSuperAdmin && row.user_status === 'LOCKED') || (row.user_id != "1" && row.user_status === 'INACTIVE')) && this.hasPermission(['users:update'])
        },
        restrictToSuperAdminChangePassword(row) {
            return (this.isSuperAdmin || (!this.isSuperAdmin && row.user_id != "1")) && this.hasPermission(['users:changepassword'])
        },
        showLockFeature(row) {
            return (this.isSuperAdmin && row.user_id != "1") && row.user_status === 'ACTIVE'
        },
        getUserList(str) {
            let _this = this;
            let params = _this.gta.payload;
            params.search = str || '';

            // TODO: preloader
            _this.preLoader(true);

            mxnUsers.doGetUsers(params).then(r => {
                _this.gta.data = r.data
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        
        getDepartment() {
            let _this = this;
            mxnDepartments.doGetDepartmentList().then(r => {
                _this.department = r.data
            }, e => {
                console.info(e)
            })
        },
        saveUserForm() {
            let _this = this;
            // return
            let params = {
                "user_email": _this.email,
                "user_password": _this.password,
                "user_roleid": _this.userType,
                "user_departmentid": _this.dept || 0,
                "user_otp_enabled": 0,
                "user_firstname": _this.firstName,
                "user_middlename": _this.middleName,
                "user_lastname": _this.lastName,
                "user_status": _this.userStatus,
                "user_branchid": _this.branch || 0,
                "timestamp": moment().toDate().getTime()
            }
            // TODO: do not proceed if the usertype is not existing
            let validateRole = this.roleList.some((r)=>{
                return r.role_id === this.userType;
              });
            //   If not found
            if (!validateRole) {
                mxnSwal.toast('error', "Please select user type.")
                return;
            }

            // TODO: preloader
            _this.searchInput = _this.gta.payload.search
            _this.preLoader(true);
            if (_this.userId) {
                delete params.user_password
                // Create user
                mxnUsers.doUpdateUser(params, _this.userId).then(r => {
                    let d = r.data;

                    if (this.userInfo.user_id === this.userId) {
                        mxnSwal.toast('success', "User Successfully Updated.")
                        localStorage.setItem("_SESSION", d.session);
                    }else{
                      mxnSwal.toast('success', d)
                    }

                    _this.viewForm();

                    // TODO: reload
                    _this.getUserList(_this.searchInput)
                }, e => {
                    console.info(e.response.data)
                    mxnSwal.toast('error', e.response.data)
                    _this.preLoader(false);
                });

                return false;
            }
            // Create user
            mxnUsers.doCreateUser(params).then(r => {
                let d = r.data;
                mxnSwal.toast('success', d.message)
                _this.viewForm();
                // TODO: reload
                _this.getUserList(_this.searchInput)
            }, e => {
                console.info(e.response.data)
                mxnSwal.toast('error', e.response.data)
                _this.preLoader(false);
            })

        },
        doSubmitForm() {
            this.$refs.submitUserForm.click()
        },
        doClearForm() {
            let _this = this;
            _this.userId = '';
            _this.firstName = '';
            _this.lastName = '';
            _this.middleName = '';
            _this.password = '';
            _this.confirmPassword = '';
            _this.email = '';
            _this.dept = '';
            _this.userType = '';
            _this.userStatus = '';
            _this.isPasswordMatched = false
            _this.branch = _this.userInfo.role_dataaccess === 'BRANCH' ? _this.userInfo.user_branchid : ''

            _this.isPwd = "password";
            _this.isPwdConfirm = "password";
        },
        doGetDetails(row) {
            console.info(row)
            let _this = this;
            _this.userId = row.user_id;
            _this.firstName = row.user_firstname;
            _this.lastName = row.user_lastname;
            _this.middleName = row.user_middlename;
            _this.password = '';
            _this.confirmPassword = '';
            _this.email = row.user_email;
            _this.dept = row.user_departmentid;
            _this.userType = row.user_roleid;
            _this.branch = row.user_branchid;
            _this.dataAccess = row.role_dataaccess;

            // _this.$emit('doView')
            _this.showModal = !_this.showModal

        },
        validateEmail(value) {
            // if the field is empty
            if (!value) {
                return 'This field is required';
            }
            // if the field is not a valid email
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return 'This field must be a valid email';
            }
            // All is good
            return true;
        },
        validatePassword(pwd, cpwd) {
            let _this = this;
            _this.isPasswordMatched = pwd !== cpwd && pwd !== '' && cpwd !== ''
        },
        viewForm() {
            let _this = this;
            _this.doClearForm();
            // _this.$emit('doView')
            _this.showModal = !_this.showModal
        },

        showUserPasswordForm(row) {
            this.isPwd = "password";
            this.isPwdConfirm = "password";

            this.userIdPasswordForm = row.user_id;
            this.isOpenUserPasswordForm = true;
            this.isPasswordMatched = false;

            this.passwordFormValue = '';
            this.confirmPassword = '';
          },

          submitPasswordForm(){
            this.preLoader(true);
            let params = {
              password: this.passwordFormValue,
              timestamp: moment().toDate().getTime()
            }
            mxnUsers.updateUserPassword(this.userIdPasswordForm, params).then(r => {
                console.info("Password Changed: ", r.data)
                let d = r.data;
                mxnSwal.toast('success', "User Successfully Updated Password.")
                // TODO: if the user update his/her owned account, should renew the session
                if (this.userInfo.user_id === this.userIdPasswordForm) {
                    localStorage.setItem("_SESSION", d.session);
                }
                this.isOpenUserPasswordForm = false;
                this.preLoader(false);
            }, e => {
                console.info(e.response.data)
                mxnSwal.toast('error', e.response.data)
                this.preLoader(false);
            });

            return false;
          },
          updateStatus(row) {
            let _this = this;
            // this.userStatus = row.user_status === 'ACTIVE' ? 'INACTIVE' : this.userStatus;
            let action = row.user_status === 'ACTIVE' ? 'deactivate' : 'activate';
            let method = row.user_status === 'ACTIVE' ? 'deactivateUserStatus' : 'activateUserStatus';

            mxnSwal.confirmation({
                icon: 'warning',
                title: "Are you sure you want to " + action + " this '" + row.user_email + "'?",
                // msg: "This action can't be undone",
                confirmed: () => {
                    _this.preLoader(true);
                    mxnUsers[method](row.user_id).then(r => {
                        mxnSwal.toast('success', r.data)
                        // this.preLoader(false);
                        this.getUserList();
                    }, e => {
                        console.info(e.response.data)
                        mxnSwal.toast('error', e.response.data)
                        this.preLoader(false);
                    })
                }
            })


          },
          updateLockStatus(row) {
            let _this = this;
            let action = row.user_status === 'ACTIVE' ? 'lock' : 'unlock';
            mxnSwal.confirmation({
                icon: 'warning',
                title: "Are you sure you want to " + action + " this '" + row.user_email + "'?",
                // msg: "This action can't be undone",
                confirmed: () => {
                    _this.preLoader(true);
                    mxnUsers.changeUserLockStatus(row.user_id).then(r => {
                        mxnSwal.toast('success', r.data)
                        // this.preLoader(false);
                        this.getUserList();
                    }, e => {
                        console.info(e.response.data)
                        mxnSwal.toast('error', e.response.data)
                        this.preLoader(false);
                    })
                }
            })
          },

    },
    mounted () {
        let _this = this;
        // TODO: load userlist
        _this.getUserList();
        // TODO: get department
        _this.getDepartment()
        // TODO: get branches
        // _this.getBranchList();
        // console.info("isSuperAdmin", this.isSuperAdmin)
    },
    components: {
        GenericTable
    }
}
