import HeaderNotice from './../HeaderNotice'

import _ from 'lodash'
import mxnAuth from '@/mixins/auth'
import mxnSwal from '@/mixins/toast'
import { mapGetters } from 'vuex'
// import swal from 'vue-sweetalert2'

export default {
    name: 'Login',
    mixins: [mxnAuth, mxnSwal],
    data() {
        return {
            isForgot: false,
            hasOTP: false,
            isPwd: "password",
            username: '',
            password: '',
            email: '',
            buttonTitle: 'Login',
            isProcessing: false,
            verificationEmail: '',
            newPassword: '',
            confirmNewPassword: '',
            isPasswordMatched: false,
            isResetPassword: false
        }
    },
    components: {HeaderNotice},
    computed: {
        ...mapGetters(['hasPermission']),

    },
    created() {
        /* console.info(process.env);
        // console.info(mxnAuth.created());
        console.info(this.$cookies)
        console.info(this.$router)
        // this.$router.go("dashboard") */
        // for reset password
        if (this.$route.query.key) {
            this.isResetPassword = true
        }
      },
    mounted() {
        console.info("mounted")
        console.log(this.$route.query)
    },
    methods: {
        viewForgotPasswordSection(event) {
            event.preventDefault();
            this.isForgot = !this.isForgot;
          console.info(event)
        },

        doLogin(e) {
            let _this = this;
            // _this.$router.push("dashboard")
            // Trap user with 3 attempt
            let attempt = _this.getWithExpiry(_this.username);
            if (attempt && parseInt(attempt) === 3) {
                mxnSwal.toast('info', "User account is locked. Please contact your administrator");
                return
            }
            // return
            _this.buttonTitle = "Processing..."
            _this.isProcessing = true;


            mxnAuth.doAuth(_this.username, _this.password).then( r => {
                // console.log("LOGIN Response", r);
                let data = r.data;

                // TODO: apply clear session
                localStorage.clear();

                // TODO: apply session
                localStorage.setItem("_SESSION", data.session);

                // TODO: current role
                localStorage.setItem("_ACCESS", btoa(JSON.stringify(data.role_permissions)));

                // TODO: store date to vuex
                this.$store.dispatch("doStoreData", data)
                // TODO: update vuex session
                this.$store.dispatch("setSession", data.session)
                // console.info('INFO: ',data)

                if (data.user_otp_enabled === '1') {
                    _this.verificationEmail = data.user_email
                    _this.doSendOTP(data.user_email);
                    _this.hasOTP = true;
                    mxnSwal.toast('success', 'Signed in successfully!')
                    return;
                }

                mxnSwal.toast('success', 'Signed in successfully!');

                // Login Notice Start
                // mxnSwal.alert({
                //   title: "Notice",
                //   message: "Web App is scheduled to be in Maintenance mode and will be inaccessible starting <strong>July 4, 2022</strong>",
                //   type: "info"}
                // );
                // Login Notice End

                // let patientAccess = ['FRONTDESK', 'BILLING', 'PATIENT', 'DIAGNOSTIC', 'RELEASING']
                if (_this.hasPermission(['users:read'])) {
                    _this.$router.push("dashboard")
                } else if (_this.hasPermission(['patients:read', 'doctors:patients'])) {
                    _this.$router.push("patients")
                } else if (_this.hasPermission(['pharmacy:read'])) {
                    _this.$router.push("pharmacy")
                }else{
                    _this.$router.push("account")
                }

                // console.info("redirecting to dashboard")
            }, e => {
                //swal("Could not log in!", "Please ensure your ID and Password", "error");
                // console.log("ERROR Login", e.response);
                let cnt = 0;

                this.buttonTitle = "Login"
                this.isProcessing = false;

                // for super admin
                if (_this.username === 'md@globalmd.com') {
                    mxnSwal.toast('error', e.response.data)
                    return;
                }

                // TODO: check if existing
                if (_this.getWithExpiry(_this.username)) {
                    cnt = parseInt(_this.getWithExpiry(_this.username)) + 1;
                } else {
                    cnt++;
                }
                // set timer 900000 = 15mins | (1000 = 1sec)
                _this.setWithExpiry(_this.username, (cnt > 3 ? 3 : cnt), 900000);

                // TODO: trap
                if ((parseInt(_this.getWithExpiry(_this.username))) === 3) {
                    mxnSwal.toast('error', "You have failed to login three times in a row, your account is now locked. Please contact you administrator.");
                    return;
                } else {
                    // increment count
                    // _this.getWithExpiry(_this.username, cnt);
                    mxnSwal.toast('error', e.response.data)
                }




            });

        },
        doGetOTP(otp) {
            let _this = this;
            console.info(otp)
            mxnAuth.doValidateOTP(otp).then( r => {
                console.log("Validate OTP", r);

            }, e => {
                //swal("Could not log in!", "Please ensure your ID and Password", "error");
                console.log("ERROR otp", e.response);

            });
        },
        doSendOTP(email) {
            let _this = this;
            // console.info(otp)
            mxnAuth.doGetOTP(email).then( r => {
                console.log("get OTP via email", r);

            }, e => {
                //swal("Could not log in!", "Please ensure your ID and Password", "error");
                console.log("ERROR otp", e.response);

            });
        },
        doCancelOTP() {
            let _this = this;
            _this.hasOTP = false
            this.buttonTitle = "Login"
            this.password = ''
            this.isProcessing = false;
        },
        submitForgotPassword(e) {
            let _this = this;
            mxnAuth.forgotPassword(_this.email).then( r => {
                console.log("Forgot password Response", r);
                mxnSwal.toast('success', r.data);
                this.email = "";
                this.isForgot = false;
            }, e => {
                //swal("Could not log in!", "Please ensure your ID and Password", "error");
                console.log("ERROR forgot password", e.response);
                mxnSwal.toast('error', e.response.data);

            });
        },
        submitResetPassword(e) {
            let _this = this;
            mxnAuth.updateUserPassword(_this.email,_this.newPassword).then( r => {
                console.log("update password Response", r);
                mxnSwal.toast('success', 'Your password has been successfully updated. You may now continue to login.');
                _this.isResetPassword = false;
                _this.$router.push("login");
            }, e => {
                //swal("Could not log in!", "Please ensure your ID and Password", "error");
                console.log("ERROR forgot password", e.response);
                mxnSwal.toast('error', e.response.data);

            });
        },
        cancelResetPasswordSection() {
            let _this = this;
            mxnSwal.confirmation({
                icon: 'info',
                title: "Are you sure you want to cancel reset password?",
                // msg: "This action can't be undone",
                confirmed: () => {
                    _this.isResetPassword = false;
                    _this.$router.push("login");
                }
            })
        },

        OTPInput() {
            let _this = this;
            const inputs = document.querySelectorAll('#otp > *[type]');
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].addEventListener('keydown', function(event) {
                    if (event.key === "Backspace") {
                        inputs[i].value = '';
                        if (i !== 0) inputs[i - 1].focus();
                    } else {
                        if (i === inputs.length - 1 && inputs[i].value !== '') {
                            return true;
                        } else if (event.keyCode > 47 && event.keyCode < 58) {
                            inputs[i].value = event.key;
                            if (i !== inputs.length - 1) inputs[i + 1].focus();
                            event.preventDefault();
                        } else if (event.keyCode > 64 && event.keyCode < 91) {
                            inputs[i].value = String.fromCharCode(event.keyCode);
                            if (i !== inputs.length - 1) inputs[i + 1].focus();
                            event.preventDefault();
                        }
                    }
                    let otp = '';
                    Array.prototype.forEach.call(inputs, e => {
                        otp += e.value;
                        if (otp.length === 6){
                            _this.doGetOTP(otp)
                        }
                    })
                });
            }
        },
        validatePassword(pwd, cpwd) {
            let _this = this;
            _this.isPasswordMatched = pwd !== cpwd && pwd !== '' && cpwd !== ''
        },
        setWithExpiry(key, value, ttl) {
            const now = new Date()

            // `item` is an object which contains the original value
            // as well as the time when it's supposed to expire
            const item = {
                value: value,
                expiry: now.getTime() + ttl,
            }
            localStorage.setItem("_ATTEMPT_" + key, JSON.stringify(item))
        },
        getWithExpiry(key) {
            const itemStr = localStorage.getItem("_ATTEMPT_"+key)

            // if the item doesn't exist, return null
            if (!itemStr) {
                return null
            }

            const item = JSON.parse(itemStr)
            const now = new Date()

            // compare the expiry time of the item with the current time
            if (now.getTime() > item.expiry) {
                // If the item is expired, delete the item from storage
                // and return null
                localStorage.removeItem("_ATTEMPT_"+key)
                return null
            }
            return item.value
        }
    },
      updated() {
          this.OTPInput()
      }
  }
