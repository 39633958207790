import swal from 'sweetalert2'
let mxnSwal = {
    created: () => {
        // init
    },
    data() {
        return {
            Toast: null
        }
    },
    toast: (icon, title) => {
        const toast = swal.mixin({
            toast: true,
            position: 'top',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', swal.stopTimer)
                toast.addEventListener('mouseleave', swal.resumeTimer)
            }
        })

        toast.fire({
            icon: icon,
            title: title
        })
    },
    confirmation: (o) => {
        // icon: "success", "error", "warning", "info" or "question"
        swal.fire({
            title: o.title,
            text: o.msg,
            icon: o.icon,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
          }).then((result) => {
            if (result.isConfirmed) {
                if (o.confirmed) {
                    o.confirmed();
                }
            }
          })
    },
    alert: (o) => {
      swal.fire(
        o.title,
        o.message,
        o.type
      )
    }
}
export default mxnSwal;
