<template>
  <main class="w-full flex-grow p-6 min-h-screen">
      <div class="w-full">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6 items-start  pb-6">
              <div class="grid grid-cols-1 gap-6">
                  <h1 class="text-3xl text-gray-700">Account</h1>
              </div>
          </div>
          <div class="flex items-center justify-center bg-white shadow-sm p-10">
              <div class="grid grid-cols-2 text-sm">
                <div class="grid p-4 font-bold text-right">{{emailLabel}}</div>
                <div class="grid p-4">{{userInfo.user_email}}</div>
                <div class="grid p-4 font-bold text-right">Full Name:</div>
                <div class="grid p-4 ">{{userInfo.user_firstname}} {{userInfo.user_middlename}} {{userInfo.user_lastname}}</div>
                <div class="grid p-4 font-bold text-right" v-if="userInfo.user_type != 'PATIENT'">User Type:</div>
                <div class="grid p-4 " v-if="userInfo.user_type != 'PATIENT'">{{roleName}}</div>
                <div class="grid p-4 font-bold text-right">Date Registered:</div>
                <div class="grid p-4 ">{{userInfo.user_created | date}}</div>
              </div>
          </div>
      </div>
  </main>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import moment from 'moment';

export default {
    name: 'Account',
    data() {
        return {

        }
    },
    computed: {
      ...mapState([ 'userInfo']),
      ...mapGetters(['roleList', 'isSuperAdmin']),
      emailLabel() {
        return (this.userInfo.user_type == 'PATIENT' ? 'Patient ID:':'Email Address:');
      },
      roleName() {
        let role = _.find(this.roleList, {role_id: this.userInfo.user_roleid}) || {};
        if(this.isSuperAdmin){
          return "SUPER ADMIN";
        }

        return role.role_name || 'UNASSIGNED';
      }
    },
}
</script>
