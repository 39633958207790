<template>

    <div class="min-h-screen bg-gray-50 py-6 flex flex-col justify-center relative overflow-hidden sm:py-12 ">
        <img src="../assets/gmhwc-logo.png" class="h-20 ml-auto mr-auto mb-1" alt="G" />

        <!-- Maintenance -->
        <div class="login-container">

            <div class="flex-col flex ml-auto mr-auto  items-center md:w-3/5 max-w-sm bg-white shadow-md pb-2">
                <p class="maintenance-reason">Our system is currently under maintenance. Please come back later.</p>
            </div>
        </div>



    </div>
</template>

<style scoped>
.maintenance-reason {
padding:1em;
text-align:center;
}
</style>
<script>

export default {
    name: 'Maintenance',
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>
