import mxnOrders from '@/mixins/orders'
import mxnPharmacy from '@/mixins/pharmacy'
import mxnSwal from '@/mixins/toast'
import GenericTable from './../GenericTable'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';

export default {
    name: 'Pharmacy',
    mixins: [mxnPharmacy, mxnSwal],
    computed: {
        ...mapState([ 'userInfo']),
        ...mapGetters(['hasPermission', 'branchList', 'isSuperAdmin', 'uploadProgress']),
        branchSelection() {
            let _this = this;
            let branchSelectionList = [];
            // if (newList.length !== oldList.length) {
                // console.info("new", newList)
            // let branches = newList;
    
            branchSelectionList = [{
                branch_id: 0,
                branch_name: "All Branches"
            }];
    
            branchSelectionList = [
                ...branchSelectionList,
                ..._this.branchList,
                {
                branch_id: -1,
                branch_name: "UNASSIGNED"
                }
            ];
    
            // TODO: initialize first selection
            
            return branchSelectionList || [];
        },

    },
    
    // watch: {
    //     '$store.state.branchList': function(newList, oldList) {
    //         this.initBranchList();
    //     }
    // },
    data() {
        return {
            dropDown: false,
            showModal: false,
            showUploadModal: false,
            isProgress: false,
            medicineName: '',
            // uploadProgress: 0,
            price: '',
            srp: '',
            quantity: '',
            branch: '',
            branchForm: '',
            code: '',
            productId: '',
            // branchSelectionList: [],
            showExportForm: false,
            exportParams: {},

            // gta
            searchInput: '',
            gta: {
                title: 'Drugs and Medications',
                search: {
                    placeholder: "Medicine Name"
                },
                header: [
                    {name: "Code"},
                    {name: "Medicine Name"},
                    {name: "Cost"},
                    {name: "Price"},
                    {name: "Stock"},
                    {name: "Action"},
                ],
                body: [
                    {name: "product_code", type: 'text'},
                    {name: "product_name", type: 'text'},
                    {name: "product_srp", type: 'currency'},
                    {name: "product_price", type: 'currency'},
                    {name: "product_stock", type: 'text'},
                    {actions: [
                        {
                            className: 'fa-user-edit text-blue-400',
                            fn: this.doGetDetails,
                            show: () => this.hasPermission(['pharmacy:update']) || this.isSuperAdmin
                        },
                        {
                            className: 'fa-info-circle text-blue-400',
                            fn: this.doGetDetails,
                            show: () => !this.hasPermission(['pharmacy:create', 'pharmacy:update']) && this.hasPermission(['pharmacy:read']) && !this.isSuperAdmin
                        },
                        
                        {
                            className: 'fa-trash text-red-400',
                            fn: this.removeProduct,
                            show: () => this.hasPermission(['pharmacy:delete']) || this.isSuperAdmin
                        },
                    ]},
                ],
                query: this.getProductList,
                data: {},
                size: 10,
                payload: {
                    branch_id: '',
                    page: 1,
                    sortField: "product_name",
                    sortType: "ASC",
                    search: ''
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        /* initBranchList() {
            let _this = this;

            _this.branchSelectionList = [{
                branch_id: 0,
                branch_name: "All Branches"
            }];

            _this.branchSelectionList = [
                ..._this.branchSelectionList,
                ..._this.branchList,
                {
                branch_id: -1,
                branch_name: "UNASSIGNED"
                }
            ];

            // TODO: initialize first selection
            _this.branch = _this.userInfo.role_dataaccess === 'BRANCH' ? _this.userInfo.user_branchid : _this.branchSelectionList[0].branch_id;
        }, */
        getProductList(str) {
            let _this = this;
            let params = _this.gta.payload;
            params.search = str || '';

            params.branch_id = this.branch

            // TODO: preloader
            _this.preLoader(true);
            

            mxnPharmacy.getProductList(params).then(r => {
                _this.gta.data = r.data

                if (this.gta.data.list) {
                    let list = _this.gta.data.list || []
                    // console.info(_this.categories)
                    let tempLis = []
                    _this.branchList.map(i => {
                        let l = list.filter(details => details.product_branchid == i.branch_id) || [];
                        if (l.length) {

                            tempLis.push({header:i.branch_name})
                            tempLis.push(...l)
                        }
                    });
                    // console.info(_this.tempLis)
                    _this.gta.data.list = tempLis || [];
                }
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        saveProductForm() {
            let _this = this;
            // return
            let params = {
                product_name: _this.medicineName,
                product_code: _this.code,
                product_price: _this.price,
                product_srp: _this.srp,
                product_stock: _this.quantity,
                product_branchid: _this.branchForm
                }

            _this.preLoader(true);
            if (_this.productId) {
                // Create user
                mxnPharmacy.updateProduct(params, _this.productId).then(r => {
                    let d = r.data;

                     mxnSwal.toast('success', d)
                    
                    _this.viewForm();

                    // TODO: reload
                    _this.getProductList(_this.searchInput)

                    _this.$store.dispatch('getProductList');
                }, e => {
                    console.info(e.response.data)
                    mxnSwal.toast('error', e.response.data)
                    _this.preLoader(false);
                });

                return false;
            }
            // Create user
            mxnPharmacy.createProduct(params).then(r => {
                let d = r.data;
                mxnSwal.toast('success', d.message)
                _this.viewForm();
                // TODO: reload
                _this.getProductList(_this.searchInput)
                _this.$store.dispatch('getProductList');
            }, e => {
                console.info(e.response.data)
                mxnSwal.toast('error', e.response.data)
                _this.preLoader(false);
            })

        },
        doSubmitForm() {
            this.$refs.submitProductForm.click()
        },
        doClearForm() {
            let _this = this;

            _this.medicineName = '';
            _this.price = '';
            _this.srp = '';
            _this.quantity = '';
            _this.branchForm = _this.userInfo.role_dataaccess ===  "BRANCH" ? _this.userInfo.user_branchid : '';;
            _this.code = '';
        },
        doGetDetails(row) {
            console.info(row)
            let _this = this;

            _this.productId = row.product_id;
            _this.medicineName = row.product_name;
            _this.price = row.product_price;
            _this.srp = row.product_srp;
            _this.quantity = row.product_stock;
            _this.branchForm = _this.hasPermission(['pharmacy:create', 'pharmacy:update']) || this.isSuperAdmin ? row.product_branchid : _.find(_this.branchList, {branch_id: row.product_branchid}).branch_name;
            _this.code = row.product_code;

            // _this.$emit('doView')
            _this.showModal = !_this.showModal

        },
        viewForm() {
            let _this = this;
            _this.doClearForm();
            // _this.$emit('doView')
            _this.showModal = !_this.showModal
        },

        handleFileUpload(event){
            let _this = this;
            let file = event.target.files[0];
            // console.info(file)
            let acceptedSize = 3072; // 3mb
            let size = Math.round(file.size / 1024);

            let acceptedFormat = ["csv"]
            let splitFilename = file.name.split(".");
            let fileType = splitFilename.pop()
            let filename = splitFilename.join('')
            let found = false;

            if (acceptedFormat.indexOf(fileType) < 0) {
                found = true;
                mxnSwal.toast('error', "File format not accepted");
            } else if (acceptedSize < size) {
                found = true;
                mxnSwal.toast('error', "File size exceed 3MB");
            }

            if (found) {
                event.target.value = '';
                return;
            }
            // console.info(_this.transactionDetails)
            // return;
            _this.isProgress = true;

            let formData = new FormData();

            setTimeout(() => {
                formData.append('file_upload', file);
                _this.$store.dispatch("importProduct", formData).then(r => {setTimeout(() => _this.showUploadModal = false, 1000)});
                setTimeout(() => _this.isProgress = false, 100)
            }, 100)


            // TODO: clear data
            event.target.value = '';
        },
        removeProduct(row) {
            let _this = this;
            mxnSwal.confirmation({
                icon: 'warning',
                title: "Are you sure you want to remove this '" + row.product_name + "'?",
                msg: "This action can't be undone",
                confirmed: () => {
                    _this.preLoader(true);
                    mxnPharmacy.deleteProduct(row.product_id).then(r => {
                        let d = r.data;
                        mxnSwal.toast('success', d)
                        // TODO: reload
                        _this.getProductList();
                        _this.$store.dispatch('getProductList');
                        // TODO: remove element
                        setTimeout(() => {
                            _this.preLoader(false);
                        }, 100)
                    }, e => {
                        console.info(e)
                        mxnSwal.toast('error', e)
                        _this.preLoader(true);
                    })
                }
            } )
        },
        exportInventory() {
            let _this = this;
            _this.preLoader(true);
            mxnPharmacy.exportProduct().then(r => {
                let d = r.data;
                // mxnSwal.toast('success', d)

                let headerLine = r.headers['content-disposition'];
                let splitHeader = headerLine.split("=")[1];
                let filename = splitHeader.trim();
                let fileURL = window.URL.createObjectURL(new Blob([d]));
                let fileLink = document.createElement('a');
			 
				fileLink.href = fileURL;
				fileLink.setAttribute('download', filename );
				document.body.appendChild(fileLink);
			  
				fileLink.click();

                // TODO: remove element
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })
        }
    },
    mounted () {
        let _this = this;
        
        // _this.initBranchList();
        _this.branch = _this.userInfo.role_dataaccess === 'BRANCH' ? _this.userInfo.user_branchid : 0;

        // TODO: load userlist
        _this.getProductList();

    },
    components: {
        GenericTable,
        Datepicker
    }
}
