import axios from 'axios';

axios.defaults.baseURL = '/server/';
var session;
let mxnDoctors = {
    getPatientDoctors: (params)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/doctors-patient',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    assignPatientDoctor: (params)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/doctor-patient',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    unassignPatientDoctor: (id)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.delete('/doctor-patient/' + id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    getTransactionDoctors: (params)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/doctors-transaction',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    getDoctorsTransactionsByAppointments: (params)=> {
        return axios.post('/doctor-transactions',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    assignTransactionDoctor: (params)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/doctor-transaction',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    unassignTransactionDoctor: (id)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.delete('/doctor-transaction/' + id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnDoctors;
