import mxnPermissions from '@/mixins/permissions'
import { mapState, mapGetters } from 'vuex'
import mxnSwal from '@/mixins/toast'

export default {
    name: 'Permissions',
    mixins: [mxnPermissions, mxnSwal],
    computed: {
        ...mapState([ 'userInfo']),
        ...mapGetters(['roleList', 'rolesAndPermissions', 'hasPermission'])
    },
    data() {
        return {
            categoryId: '',
            category: '',
            isOpen: false,
            accessList: [],
            dataAccess: ['GLOBAL', 'BRANCH'],
            departmentAccess: ['GLOBAL', 'DEPARTMENT'],
            access: '',
            department: '',
            roleName: '',
            // userPermissionType: '',
            // gta

        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        savePermissionsForm(s) {
            let _this = this;
            // return
            let params = {
                "role_name": _this.roleName.toUpperCase(),
                "role_permissions": _this.accessList,
                "role_dataaccess": _this.access,
                "role_departmentaccess": _this.department,
            }

            // TODO: preloader
            _this.preLoader(true);
            if (_this.roleId) {
                // Create user
                mxnPermissions.updatePermission(params, _this.roleId).then(r => {
                    let d = r.data;
                    mxnSwal.toast('success', d)
                    _this.viewForm()
                    // TODO: reload
                    this.$store.dispatch('getRoles');
                }, e => {
                    console.info(e)
                    mxnSwal.toast('error', e)
                    _this.preLoader(true);
                });

                return false;
            }
            // Create user
            mxnPermissions.createPermission(params).then(r => {
                let d = r.data;
                mxnSwal.toast('success', d)
                _this.viewForm()
                // TODO: reload
                this.$store.dispatch('getRoles')
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })

        },
        doSubmitForm() {
            this.$refs.submitAccessForm.click();
        },
        doClearForm() {
            let _this = this;
            _this.roleId = ''
            _this.roleName = ''
            _this.access = ''
            _this.department = ''
            _this.accessList = []
        },
        removePermission(data) {
            let _this = this;
            mxnSwal.confirmation({
                icon: 'warning',
                title: "Are you sure you want to remove '" + data.role_name + "'?",
                msg: "This action can't be undone",
                confirmed: () => {
                    _this.preLoader(true);
                    this.$store.dispatch('removePermission', data.role_id)
                }
            } )

        },
        updatePermission(d) {
            let _this = this;
            _this.viewForm();
            _this.roleId = d.role_id
            _this.roleName = d.role_name
            _this.access = d.role_dataaccess
            _this.department = d.role_departmentaccess
            _this.accessList = d.role_permissions
        },
        viewForm() {
            let _this = this;
            _this.doClearForm();
            _this.isOpen = !_this.isOpen
        }

    },
    mounted () {
        let _this = this;
        // TODO: load category list
        // _this.getPermissionList();

        this.$store.dispatch('getPermissions')

        // console.info(this.hasPermission(['users:create','users:read1']))

    }
}
