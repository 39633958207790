<template>
<div>
  <!-- Notice -->
  <div class="header-notice">
    <i class="fa-solid fa-triangle-exclamation"></i> Web App is scheduled to be in Maintenance mode and will be inaccessible starting <strong>July 4, 2022</strong>.
  </div>
</div>
</template>

<script>

export default {
    name: 'HeaderNotice',
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>
