import axios from 'axios';

axios.defaults.baseURL = '/server/';
let mxnCardTypes = {
    doGetCardType: (params)=> {
        return axios.post('/card-types',
        params,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doGetCardTypeById: (id)=> {
        return axios.get('/card-type/' + id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doDelete: (id)=> {
        return axios.delete('/card-type/' + id,
         {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doCreate: (params)=> {
        return axios.post('/card-type',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doUpdate: (params, id)=> {
        return axios.put('/card-type/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnCardTypes;
