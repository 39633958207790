import mxnOrders from '@/mixins/orders'
import mxnOrderItems from '@/mixins/orderItems'
import mxnSwal from '@/mixins/toast'
import GenericTable from './../GenericTable'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';

export default {
    name: 'Orders',
    mixins: [mxnOrders, mxnSwal],
    computed: {
        ...mapState([ 'userInfo']),
        ...mapGetters(['hasPermission', 'branchList', 'productList', 'isSuperAdmin']),
        branchSelection() {
            let _this = this;
            let branchSelectionList = [];
            // if (newList.length !== oldList.length) {
                // console.info("new", newList)
            // let branches = newList;
    
            branchSelectionList = [{
                branch_id: 0,
                branch_name: "All Branches"
            }];
    
            branchSelectionList = [
                ...branchSelectionList,
                ..._this.branchList,
                {
                branch_id: -1,
                branch_name: "UNASSIGNED"
                }
            ];
    
            // TODO: initialize first selection
            
            return branchSelectionList || [];
        },
    },
    /* watch: {
        '$store.state.branchList': function(newList, oldList) {
            this.initBranchList();
        }
    }, */
    data() {
        return {
            dropDown: false,
            showModal: false,
            showUploadModal: false,
            isProgress: false,
            showExportForm: false,
            exportParams: {
                start_date: '',
                end_date: ''
            },
            uploadProgress: 0,
            orderId: '',
            // branchSelectionList: [],
            filteredProductList: [],
            orderDetails: [],
            orderItems: [
                {
                    item_id: '',
                    product_id: '',
                    order_id: '',
                    quantity: ''
                }
            ],
            placedOrderItems: [],
            branch: '',
            branchOrder: '',
            enabledDiscount: false,
            discountList : [{label: '5%', value: 0.05}, {label: '10%', value: 0.1}, {label: '20%', value: 0.2}, {label: '30%', value: 0.3}, {label: '40%', value: 0.4}, {label: '50%', value: 0.5}],
            discountName: '',
            discountPercentage: 0.2,
            computedTotal: {},

            // gta
            searchInput: '',
            gta: {
                title: 'Order List',
                search: {
                    show: false,
                    placeholder: "Order Number"
                },
                menu: {
                    show: () => (this.hasPermission(['pharmacy:report']) || this.isSuperAdmin) && false,
                    actions: [
                        {
                            show: () => (this.hasPermission(['pharmacy:report']) || this.isSuperAdmin),
                            label: "Generate Sales Report",
                            icon: true,
                            iconClass: 'fa-chart-area',
                            fn: this.exportForm
                        }
                    ]
                },
                header: [
                    {name: "Order No."},
                    {name: "No. of Item"},
                    {name: "Total"},
                    {name: "Order Date"},
                    {name: "Status"},
                    {name: "Action"},
                ],
                body: [
                    {name: "order_id", type: 'text'},
                    {name: "", type: 'method', fn: row => row.items.length},
                    {name: "", type: 'method', fn: row => '₱ ' + (row.order_discountlabel === '' ? row.totalOrderCost : row.grandTotal)},
                    {name: "order_created", type: 'date'},
                    {name: "order_status", type: 'text'},
                    {actions: [
                        {
                            className: 'fa-user-edit text-blue-400',
                            fn: this.doGetDetails,
                            show: (row) => (this.hasPermission(['orders:update']) && ['CANCELLED', 'PAID'].indexOf(row.order_status) < 0) && !this.isSuperAdmin
                        },
                        
                        {
                            className: 'fa-info-circle text-blue-400',
                            fn: this.doGetDetails,
                            show: (row) => (['CANCELLED', 'PAID'].indexOf(row.order_status) >= 0 || !(this.hasPermission(['orders:update']))) || this.isSuperAdmin
                        },

                        {
                            className: 'fa-print text-blue-400',
                            fn: this.printOrderDetails,
                            show: (row) => this.hasPermission(['orders:read']) || this.isSuperAdmin
                        }
                    ]},
                ],
                query: this.getOrderList,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "order_id",
                    sortType: "DESC",
                    search: ''
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        /* initBranchList() {
            let _this = this;

            _this.branchSelectionList = [{
                branch_id: 0,
                branch_name: "All Branches"
            }];

            _this.branchSelectionList = [
                ..._this.branchSelectionList,
                ..._this.branchList,
                {
                branch_id: -1,
                branch_name: "UNASSIGNED"
                }
            ];

            // TODO: initialize first selection
            _this.branch = _this.userInfo.role_dataaccess === 'BRANCH' ? _this.userInfo.user_branchid : _this.branchSelectionList[0].branch_id;
        }, */
        getOrderList(str) {
            let _this = this;
            let params = _this.gta.payload;
            params.search = str || '';

            params.branch_id = _this.branch
            
            // TODO: preloader
            _this.preLoader(true);

            mxnOrders.getOrderList(params).then(r => {
                _this.gta.data = r.data
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
                _this.preLoader(false);
            })
        },
        getOrderItemList(){
            mxnOrderItems.getOrderList(id).then(r => {
                console.info(r.data)
                // get oreder details per row
                // _this.orderDetails = r.data;

                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
                _this.preLoader(false);
            })
        },
        getFilteredProductList() {
            let _this = this;
            _this.filteredProductList = [];
            let l = _this.productList.filter(details => details.product_branchid == _this.branchOrder) || [];
            _this.filteredProductList = l;

            // reset list
            _this.orderItems = [];
            _this.addOrderItem()

            _this.computedItemPrice();
        },
        saveOrderItem(i) {
            let _this = this;
            let details = _this.orderItems[i];
            
            let params = {
                order_id: this.orderId,
                product_id: details.product_id,
                quantity: details.quantity
            }

            if (details.item_id) {
                mxnOrderItems.updateOrderItem(params, details.item_id).then(r => {
                    let d = r.data
    
                    // console.info(_this.orderItems)
                    // TODO: end preloader
                    setTimeout(() => {
                        _this.preLoader(false);
                    }, 100)
                }, e => {
                    console.info(e)
                    _this.preLoader(false);
                })

                return
            }

            mxnOrderItems.createOrderItem(params).then(r => {
                let d = r.data
                _this.orderItems[i].item_id = d.item_id;


                console.info(_this.orderItems)
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
                _this.preLoader(false);
            })
        },
        saveOrderForm() {
            let _this = this;
            // return
            let params = {
                order_status: 'PAID',
                order_branchid: _this.branchOrder,
                order_discountlabel: _this.enabledDiscount ? _this.discountName : '',
                order_discountpercent: _this.enabledDiscount ? _this.discountPercentage : 0,
                timestamp: moment().toDate().getTime(),
                items: []
                }

            _this.preLoader(true);
            if (_this.orderId) {
                delete params.items
                // Create user
                mxnOrders.updateOrder(params, _this.orderId).then(r => {
                    let d = r.data;

                     mxnSwal.toast('success', d)
                    
                    _this.viewForm();

                    // TODO: reload
                    _this.getOrderList(_this.searchInput)
                }, e => {
                    console.info(e.response.data)
                    mxnSwal.toast('error', e.response.data)
                    _this.preLoader(false);
                });

                return false;
            }

            // TODO: construct params for items
            _this.orderItems.map(row => {
                params.items.push({
                    product_id: row.product_id,
                    quantity: row.quantity
                })
            })

            // Create user
            mxnOrders.createOrder(params).then(r => {
                let d = r.data;
                mxnSwal.toast('success', d.message)
                _this.viewForm();
                // TODO: reload
                _this.getOrderList(_this.searchInput)
            }, e => {
                console.info(e.response.data)
                mxnSwal.toast('error', e.response.data)
                _this.preLoader(false);
            })

        },
        cancelOrder() {
            let _this = this;
            mxnSwal.confirmation({
                icon: 'warning',
                title: "Are you sure you want to cancel this order #" + _this.orderId + "?",
                msg: "This action can't be undone",
                confirmed: () => {
                    _this.preLoader(true);
                    mxnOrders.deleteOrder(_this.orderId).then(r => {
                        let d = r.data
                        mxnSwal.toast('success', d)

                        _this.viewForm()
                        // TODO: end preloader
                        setTimeout(() => {
                            _this.preLoader(false);
                        }, 100)
                    }, e => {
                        console.info(e)
                        _this.preLoader(false);
                    })
                }
            } )
        },
        doSubmitForm() {
            this.$refs.submitOrderForm.click()
        },
        doClearForm() {
            let _this = this;

            _this.orderId = '';
            _this.branchOrder = _this.userInfo.role_dataaccess ===  "BRANCH" ? _this.userInfo.user_branchid : '';
            _this.getFilteredProductList();
            _this.discountName = '';
            _this.discountPercentage = 0.2;
            _this.enabledDiscount = false;

            _this.orderItems = [];
            _this.addOrderItem();

            _this.orderDetails = [];
            _this.computedTotal = {};
        },
        doGetDetails(row) {
            // console.info(row)
            let _this = this;

            _this.orderId = row.order_id;

            // _this.branchOrder = row.order_branchid;
            _this.branchOrder = _this.hasPermission(['orders:create', 'orders:update']) ? row.order_branchid : _.find(_this.branchList, {branch_id: row.order_branchid}).branch_name;
            // _this.getFilteredProductList();

            if (row.order_discountlabel) {
                _this.enabledDiscount = true;
                _this.discountName = row.order_discountlabel
                _this.discountPercentage = row.order_discountpercent
            }

            _this.orderItems = [];
            if (row.items.length) {

                _this.placedOrderItems = row.items;

                // TODO: get items
                row.items.map(row => {
                    
                    _this.orderItems.push({
                        item_id: row.item_id,
                        product_id: row.item_productid,
                        order_id: row.item_orderid,
                        quantity: row.item_quantity
                    })
                })
                
            }
            // get oreder details per row
            _this.orderDetails = row;

            _this.computedItemPrice()

            // _this.$emit('doView')
            _this.showModal = !_this.showModal

        },
        viewForm() {
            let _this = this;
            _this.doClearForm();
            // _this.$emit('doView')
            if (_this.showModal) {

                _this.getOrderList()
            }
            _this.getFilteredProductList();
            _this.showModal = !_this.showModal
        },

        handleFileUpload(event){
            let _this = this;
            let file = event.target.files[0];
            // console.info(file)
            let acceptedSize = 3072; // 3mb
            let size = Math.round(file.size / 1024);

            let acceptedFormat = ["csv"]
            let splitFilename = file.name.split(".");
            let fileType = splitFilename.pop()
            let filename = splitFilename.join('')
            let found = false;

            if (acceptedFormat.indexOf(fileType) < 0) {
                found = true;
                mxnSwal.toast('error', "File format not accepted");
            } else if (acceptedSize < size) {
                found = true;
                mxnSwal.toast('error', "File size exceed 3MB");
            }

            if (found) {
                event.target.value = '';
                return;
            }
            // console.info(_this.transactionDetails)
            // return;
            _this.isProgress = true;

            let formData = new FormData();

            setTimeout(() => {
                formData.append('upload', file);
                formData.append('dms_transactionid', _this.transactionDetails.transactionId);
                formData.append('dms_patientid', _this.patientInfo.patient_id);
                formData.append('dms_name', filename);
                formData.append('dms_type', 'RESULT');
                formData.append('timestamp', moment().toDate().getTime());
                _this.$store.dispatch("uploadResultFile", formData);
                setTimeout(() => _this.isProgress = false, 100)
            }, 100)


            // TODO: clear data
            event.target.value = '';
        },
        addOrderItem() {
            this.orderItems.push({
                item_id: '',
                product_id: '',
                order_id: '',
                quantity: ''
            })
        },
        computedItemPrice() {
            let _this = this;
            let total = 0;
            let discounted = 0;
            let grandTotal = 0;
            _this.orderItems.map(i => {
                let row = _.find(_this.productList, {product_id: i.product_id});
                if (row && i.quantity) {
                    total += parseFloat(row.product_price) * parseInt(i.quantity);
                }
                
            })
            discounted = parseFloat(_this.discountPercentage) * total;
            grandTotal = total - discounted;

            _this.computedTotal = {
                total: total || 0,
                discounted: discounted || 0,
                grandTotal: grandTotal || 0
            }
        },
        buildOrderParams(item, i) {
            let _this = this;
            let row = _.find(_this.productList, {product_id: item.product_id});

            if (!row) return

            // TODO: limit quantity based on stock
            if (parseInt(_this.orderItems[i].quantity) > parseInt(row.product_stock)) {
                _this.orderItems[i].quantity = '';
                mxnSwal.toast('info', 'Quantity should not be more than ' + row.product_stock + ' number of stocks');
                return
            }

            
            // if new item
            if (!item.item_id && _this.orderId) {
                _this.orderItems[i].order_id = _this.orderId
                // _this.orderItems[i].product_name = row.product_name
                // _this.orderItems[i].product_price = row.product_price

                if (_this.orderItems[i].quantity && _this.orderItems[i].product_name) {
                    _this.saveOrderItem(i);
                }
            } else if (_this.orderId){
                _this.saveOrderItem(i);
            } 

            _this.computedItemPrice();

            console.info(row.product_stock);
            // console.info(_this.orderItems[i])
            
        },
        checkOrderItem(prod_id) {
            return !this.orderItems.some(row => row.product_id === prod_id)
        },
        removeOrderItem(i) {
            let _this = this;
            let details = _this.orderItems[i];
            let row = _.find(_this.productList, {product_id: details.product_id});
            // console.info(details);
            if (details.item_id) {
                mxnSwal.confirmation({
                    icon: 'warning',
                    title: "Are you sure you want to remove this '" + row.product_name + "'?",
                    msg: "This action can't be undone",
                    confirmed: () => {
                        _this.preLoader(true);
                        mxnOrderItems.deleteOrderItem(details.item_id).then(r => {
                            let d = r.data
                            _this.$delete(_this.orderItems, i);
                            // TODO: update order
                            // _this.getOrderItemList()
                            _this.computedItemPrice()
                            // TODO: end preloader
                            setTimeout(() => {
                                _this.preLoader(false);
                            }, 100)
                        }, e => {
                            console.info(e)
                            _this.preLoader(false);
                        })
                    }
                } )
                
            } else {
                this.$delete(this.orderItems, i);
                _this.computedItemPrice();
            }

        
        },
        exportForm() {
            let _this = this;
            _this.exportParams.branch = _this.userInfo.role_dataaccess ===  "BRANCH" ? _this.userInfo.user_branchid : '';
            _this.exportParams.start_date = new Date();
            _this.exportParams.end_date = new Date();
            this.showExportForm = true
            
        },
        exportSalesReport() {
            let _this = this;
            let params = {
                branch_id: _this.exportParams.branch,
                start_date: moment(_this.exportParams.start_date).hours(0).minutes(0).seconds(0).milliseconds(0).toDate().getTime(),
                end_date: moment(_this.exportParams.end_date).hours(23).minutes(59).seconds(59).milliseconds(59).toDate().getTime()
            }
            // console.info(_this.exportParams);

            if (_this.exportParams.start_date && _this.exportParams.end_date && _this.exportParams.branch) {
                _this.preLoader(true);
                mxnOrders.exportOrdersReport(params).then(r => {
                    let d = r.data;
                    // console.info(d)
                    let headerLine = r.headers['content-disposition'];
                    // let startFileNameIndex = headerLine.indexOf('"') + 1
                    // let endFileNameIndex = headerLine.lastIndexOf('"');
                    let splitHeader = headerLine.split("=")[1];
                    // let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
                    let filename = splitHeader.trim();
                    let fileURL = window.URL.createObjectURL(new Blob([d]));
                    let fileLink = document.createElement('a');
                
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', filename );
                    document.body.appendChild(fileLink);
                
                    fileLink.click();
                    _this.exportParams = {}
                    _this.showExportForm = false;
                    // TODO: remove element
                    setTimeout(() => {
                        _this.preLoader(false);
                    }, 100)
                }, e => {
                    console.info(e)
                    mxnSwal.toast('error', e)
                    _this.preLoader(true);
                })
            }
        },

        printOrderDetails(row) {
            window.open("/server/pdf/order/" + row.order_id);
        },

        // getSelectedBranch() {
        //     let _this = this;
        //     _this.gta.payload.branch_id = this.branch
        //     _this.getOrderList();
        // },
    },
    mounted () {
        let _this = this;
        _this.branch = _this.userInfo.role_dataaccess === 'BRANCH' ? _this.userInfo.user_branchid : 0;
        // _this.initBranchList();

        // TODO: load userlist
        _this.getOrderList();


    },
    components: {
        GenericTable,
        Datepicker
    }
}
