import mxnTransactionTypes from '@/mixins/transactionTypes'
import mxnDepartments from '@/mixins/departments'
import mxnBranches from '@/mixins/branches'
import mxnCategories from '@/mixins/categories'
import GenericTable from './../GenericTable'
import mxnSwal from '@/mixins/toast'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment';

export default {
    name: 'TransactionTypes',
    mixins: [mxnTransactionTypes, mxnDepartments, mxnBranches, mxnCategories],
    computed: {
        ...mapGetters(['branchList']),
        branchSelection() {
            let _this = this;
            let branchSelectionList = [];
            // if (newList.length !== oldList.length) {
                // console.info("new", newList)
            // let branches = newList;

            branchSelectionList = [{
                branch_id: 0,
                branch_name: "All Branches"
            }];

            branchSelectionList = [
                ...branchSelectionList,
                ..._this.branchList,
                {
                branch_id: -1,
                branch_name: "UNASSIGNED"
                }
            ];

            // TODO: initialize first selection
            // _this.branch = _this.branchSelectionList[0].branch_id;
            return branchSelectionList || [];
        }
    },
    props: {
        categories: Array,
        departments: Array,
        branches: Array,
        loadType: Boolean
    },
    
    data() {
        return {
            transactionList: [],
            categoryList: [],
            // branchSelectionList: [],
            deptList: [],
            branch: 0,
            isOpen: false,
            category: '',
            department: '',
            transactionId: '',
            transactionName: '',
            description: '',
            branchId: '',
            categoryId: '',
            departmentId: '',
            cost: '',
            // gta
            searchInput: '',
            gta: {
                // title: 'Transaction Types',
                search: {
                    placeholder: "Type Name"
                },
                header: [
                    {name: "Name", field:"type_name", sortable:true},
                    // {name: "Department"},
                    // {name: "Category"},
                    {name: "Branch"},
                    {name: "Cost", field:"type_cost", sortable:true},
                    {name: "Action"}
                ],
                body: [
                    {name: "type_name", type: 'text'},
                    // {name: "type_departmentid", type: 'method', fn: this.getDepartmentName},
                    // {name: "type_categoryid", type: 'method', fn: this.getCategoryName},
                    {name: "type_branchid", type: 'method', fn: this.getBranchName},
                    {name: "type_cost", type: 'currency'},
                    {actions: [
                        {
                            className: 'fa-edit text-blue-400',
                            fn: this.getTransactionTypeDetails
                        },
                        {
                            className: 'fa-trash text-blue-400',
                            fn: this.removeTransactionType
                        },
                    ]},
                ],
                query: this.getTransactionTypes,
                data: {total:0},
                size: 10,
                payload: {
                    page: 1,
                    search: '',
                    sortField: "type_name",
                    sortType: "ASC",
                    branch_id: 2
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        getTransactionTypes(str) {
            let _this = this;
            let params = _this.gta.payload;
            params.branch_id = _this.branch
            params.search = str || ''
            // TODO: preloader
            _this.preLoader(true);

            mxnTransactionTypes.getTransactionTypesByBranchId(params).then(r => {
                _this.gta.data = r.data
                if (this.gta.data.list) {
                    let list = _this.gta.data.list || []
                    // console.info(_this.categories)
                    _this.transactionList = []
                    _this.categories.map(i => {
                        let l = list.filter(details => details.type_categoryid == i.category_id) || [];
                        if (l.length) {

                            _this.transactionList.push({header:i.category_name})
                            _this.transactionList.push(...l)
                        }
                    });
                    // console.info(_this.transactionList)
                    _this.gta.data.list = _this.transactionList || []
                }

                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },

        doClearForm() {
            let _this = this;
            _this.transactionId = '';
            _this.transactionName = '';
            _this.description = '';
            _this.branchId = 0;
            _this.categoryId = '';
            _this.departmentId = '';
            _this.cost = '';
        },
        getDetailsForm(data) {
            let _this = this;
            _this.transactionId = data.type_id;
            _this.transactionName = data.type_name;
            _this.description = data.type_description;
            _this.branchId =  data.type_branchid;
            _this.categoryId =  data.type_categoryid;
            _this.departmentId =  data.type_departmentid;
            _this.cost =  data.type_cost;
        },
        saveTransactionForm() {
            let _this = this;
            // return
            let params = {
                "type_name": _this.transactionName,
                "type_description": _this.description,
                "type_departmentid": parseInt(_this.departmentId),
                "type_branchid": parseInt(_this.branchId),
                "type_categoryid": parseInt(_this.categoryId),
                "type_cost": parseFloat(_this.cost)
            }

            // TODO: preloader
            _this.preLoader(true);
            if (_this.transactionId) {
                // Create user
                mxnTransactionTypes.updateTransactionType(params, _this.transactionId).then(r => {
                    let d = r.data;
                    mxnSwal.toast('success', d)
                    _this.viewForm();
                }, e => {
                    console.info(e)
                    mxnSwal.toast('error', e)
                    _this.preLoader(true);
                });

                return false;
            }
            // Create user
            mxnTransactionTypes.createTransactionType(params).then(r => {
                let d = r.data;
                mxnSwal.toast('success', d.message)
                _this.viewForm();
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })
        },
        getTransactionTypeDetails(row) {
            let _this = this;
            // _this.viewForm()
            // Create user
            _this.preLoader(true);
            mxnTransactionTypes.viewTransactionType(row.type_id).then(r => {
                let d = r.data;
                _this.viewForm();
                _this.getDetailsForm(d)
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })
        },
        removeTransactionType(row) {
            let _this = this;
            mxnSwal.confirmation({
                icon: 'warning',
                title: "Are you sure you want to remove this '" + row.type_name + "'?",
                msg: "This action can't be undone",
                confirmed: () => {
                    _this.preLoader(true);
                    mxnTransactionTypes.deleteTransactionType(row.type_id).then(r => {
                        let d = r.data;
                        mxnSwal.toast('success', d)
                        // TODO: reload
                        _this.getTransactionTypes(_this.gta.payload.search)
                        // TODO: remove element
                        setTimeout(() => {
                            _this.preLoader(false);
                        }, 100)
                    }, e => {
                        console.info(e)
                        mxnSwal.toast('error', e)
                        _this.preLoader(true);
                    })
                }
            } )
        },
        doSubmitForm() {
            this.$refs.submiTransactionForm.click();
        },
        viewForm() {

            let _this = this;
            _this.doClearForm();
            _this.isOpen = !_this.isOpen

            if (!_this.isOpen) {
                // TODO: reload
                _this.getTransactionTypes(_this.gta.payload.search)
            }
        },
        getDepartmentName(row) {
            let department =  _.find(this.departments, {department_id: row.type_departmentid});
            return (department ? department.department_name : "");
        },
        getCategoryName(row) {
            let category = _.find(this.categories, {category_id: row.type_categoryid});
            return (category ? category.category_name : "");
        },
        getBranchName(row) {
            let branch = _.find(this.branchList, {branch_id: row.type_branchid});
            return (branch ? branch.branch_name : "");
        },

        /* getBranchList() {
            let _this = this;

              // if (newList.length !== oldList.length) {
                  // console.info("new", newList)
              let branches = this.branchList;

              _this.branchSelectionList = [{
                  branch_id: 0,
                  branch_name: "All Branches"
              }];

              _this.branchSelectionList = [
                  ..._this.branchSelectionList,
                  ...branches,
                  {
                  branch_id: -1,
                  branch_name: "UNASSIGNED"
                  }
              ];

              // TODO: initialize first selection
              _this.branch = _this.branchSelectionList[0].branch_id;
        }, */
    },
    mounted() {
        let _this = this;
        // TODO: load userlist
        _this.getTransactionTypes();

        // _this.getBranchList()

        // _this.getCategoryList()

        // _this.getDepartmentList()
    },
    updated() {
        // console.info(this.categories)
        // console.info(this.departments)
        // console.info(this.branches)
    },
    components: {
        GenericTable
    }
}
