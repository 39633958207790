import axios from 'axios';
import { mapState } from 'vuex'

axios.defaults.baseURL = '/server/';
var session
let mxnOrder = {
    getOrderList: (params)=> {
        return axios.post('/orders',
        params,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    getOrderById: (id)=> {
        return axios.get('/order/' + id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    deleteOrder: (id)=> {
        return axios.delete('/order/' + id,
         {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    createOrder: (params)=> {
        return axios.post('/order',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    updateOrder: (params, id)=> {
        return axios.put('/order/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    exportOrdersReport: (params)=> {
        return axios.post('/pharmacy-report',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnOrder;
