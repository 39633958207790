import axios from 'axios';

axios.defaults.baseURL = '/server/';
let mxnCards = {
    doGetCards: (params)=> {
        return axios.post('/cards',
        params,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doGetCardById: (id)=> {
        return axios.get('/card/' + id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doDeleteCard: (id)=> {
        return axios.delete('/card/' + id,
         {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doCreateCard: (params)=> {
        return axios.post('/card',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doUpdateCard: (params, id)=> {
        return axios.put('/card/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    assignPatient: (params)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/card-assignment',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    unassignPatient: (id)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.delete('/card-assignment/' + id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    getUtilizationDetails: (params,id)=> {
        return axios.post('/card-appointments/' + id,
        params,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    getPatientCardById: (id)=> {
        return axios.get('/patient-card/' + id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnCards;
